import { Divider, Flex } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { fromPlaceId, toMfAddressComponents } from "mf-google-geocoder";
import React from "react";
import { Messages, ResponseOf } from "../../../core/api";
import { GOOGLE_MAPS_API_KEY } from "../../../shared/consts";
import useGoogleMapsAddress from "../../../shared/hooks/useGoogleMapsAddress";
import { NoteId, PatientId } from "../../../shared/schema/schema";
import { PatientIntakeProfileUpdate } from "../patient-intake.types";
import IntakePatientCommunicationLog from "./IntakePatientCommunicationLog";
import IntakePatientProfileNotesSection from "./IntakePatientProfileNotesSection";
import IntakeProfileContactInfoSection from "./IntakeProfileContactInfoSection";
import IntakeProfileMedInfoSection from "./IntakeProfileMedInfoSection";
import IntakeProfileNYIAInfo from "./IntakeProfileNYIAInfo";
import IntakeProfilePersonalInfoSection from "./IntakeProfilePersonalInfoSection";
import IntakeProfilePlanInfo, { IntakePlan } from "./IntakeProfilePlanInfo";

interface Props {
  intakePlans: IntakePlan[];
  patientIntakeProfile: Messages["IntakePatientProfile"];
  intakeStatuses: ResponseOf<"get", "./patient_intake/intake_status">["intakeStatuses"];
  eligibilityCheckResults: ResponseOf<"get", "./eligibility_checks">["records"];
  communicationLog: ResponseOf<"get", "./comm_center/tickets/patients/:patientId">["tickets"];
  isEditMode: boolean;
  onClickMarkNoteAsDone: (noteId: NoteId) => void;
  onClickAddNewNote: (patientId: PatientId) => void;
  onClickEligibilityCheck: (pateintId: PatientId) => void;
  onEditPatientIntakeProfile: (
    fieldOrObject: keyof PatientIntakeProfileUpdate | PatientIntakeProfileUpdate,
    value?: PatientIntakeProfileUpdate[keyof PatientIntakeProfileUpdate]
  ) => void;
}

const IntakePatientProfileBody = (props: Props) => {
  const handleEmergencyContactChange = (
    field: "firstName" | "lastName" | "mobilePhoneNumber" | "homePhoneNumber" | "relationship",
    value: string
  ) => {
    const prevFamilyInformation = props.patientIntakeProfile.familyInformation;
    if (prevFamilyInformation === null || prevFamilyInformation.at(0) === undefined) {
      const emptyContact = {
        firstName: "",
        lastName: "",
        homePhoneNumber: "",
        mobilePhoneNumber: "",
        relationship: "",
      };

      props.onEditPatientIntakeProfile("familyInformation", [{ ...emptyContact, [field]: value }]);
    } else {
      const newFamilyInformation = {
        ...prevFamilyInformation[0],
        [field]: value,
      };

      const newFamilyContactsArray = props.patientIntakeProfile.familyInformation;
      prevFamilyInformation[0] = newFamilyInformation;
      props.onEditPatientIntakeProfile("familyInformation", newFamilyContactsArray);
    }
  };

  const {
    handleChange: handleChangeAddress,
    placePredictions,
    getDetails: getAddressDetails,
    showPredictions,
    setShowPredictions,
  } = useGoogleMapsAddress();

  const handleClickAddress = (prediction: google.maps.places.AutocompletePrediction) => {
    handleClickAddressPrediction(prediction).then(({ address, addressComponents }) =>
      props.onEditPatientIntakeProfile({ address, addressComponents })
    );
  };

  const handleClickAddressPrediction = async (
    prediction: google.maps.places.AutocompletePrediction
  ): Promise<{
    address: string | null;
    addressComponents: Messages["IntakePatientProfile"]["addressComponents"] | null;
  }> => {
    setShowPredictions(false);
    let address: string | null = null;
    let addressComponents: Messages["IntakePatientProfile"]["addressComponents"] | null = null;

    try {
      const result = await getAddressDetails(prediction);
      const placeId = result?.place_id;

      if (placeId === undefined) {
        return {
          address: null,
          addressComponents: null,
        };
      }

      const addressDetails = await fromPlaceId(placeId, {
        apiKey: GOOGLE_MAPS_API_KEY,
        mfAutoFix: true,
      });
      addressComponents = {
        components: toMfAddressComponents(addressDetails),
        text: addressDetails.fullAddress,
        timezone: "America/New_York",
      };
      address = prediction.description;
      return { address, addressComponents };
    } catch (error) {
      console.error("Error getting address details", error);
      return { address: null, addressComponents: null };
    }
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeAddress(event);
    props.onEditPatientIntakeProfile({ address: event.target.value, addressComponents: null });
  };

  const handlePatientPhoneNumberChange = (value: string) => {
    if (!isEmpty(value)) {
      props.onEditPatientIntakeProfile("phoneNumbers", [
        {
          ...props.patientIntakeProfile.phoneNumbers[0],
          phonenumber: value,
          type: "MOBILE",
          label: null,
          description: null,
          patientId: props.patientIntakeProfile.id,
        },
      ]);
    } else {
      props.onEditPatientIntakeProfile("phoneNumbers", []);
    }
  };

  const patientPhoneNumber =
    props.patientIntakeProfile.phoneNumbers.length > 0
      ? props.patientIntakeProfile.phoneNumbers[0].phonenumber
      : "";

  return (
    <Flex direction="column" gap={4} marginBottom="50px">
      <IntakeProfilePersonalInfoSection
        isEditMode={props.isEditMode}
        places={placePredictions}
        showPredictions={showPredictions}
        intakeStatuses={props.intakeStatuses}
        patientInfo={{
          address: props.patientIntakeProfile.address,
          address2: props.patientIntakeProfile.address2,
          addressInstructions: props.patientIntakeProfile.addressInstructions,
          addressComponents: props.patientIntakeProfile.addressComponents,
          createdAt: props.patientIntakeProfile.createdAt,
          dateOfBirth: props.patientIntakeProfile.dateOfBirth,
          eligibilityFlow: props.patientIntakeProfile.eligibilityFlow,
          gender: props.patientIntakeProfile.gender,
          intakeStatus: props.patientIntakeProfile.intakeStatus,
          lastCallDate: props.patientIntakeProfile.lastCallDate,
          nextCallDate: props.patientIntakeProfile.nextCallDate,
        }}
        onChangePatientInfoField={(field, value) => props.onEditPatientIntakeProfile(field, value)}
        onChangeAddress={handleAddressChange}
        onClickAddress={handleClickAddress}
      />

      <Divider variant="solid"></Divider>
      <IntakeProfileMedInfoSection
        isEditMode={props.isEditMode}
        patientId={props.patientIntakeProfile.id}
        medicaidId={props.patientIntakeProfile.medicaidId}
        medicareId={props.patientIntakeProfile.medicareId}
        medicaidStatus={props.patientIntakeProfile.medicaidStatus}
        eligibilityCheckResults={props.eligibilityCheckResults}
        onChangeIds={(field, value) => props.onEditPatientIntakeProfile(field, value)}
        onClickCheckEligibility={props.onClickEligibilityCheck}
      />

      <Divider variant="solid"></Divider>
      <IntakeProfileContactInfoSection
        isEditMode={props.isEditMode}
        patientPhoneNumber={patientPhoneNumber}
        patientEmail={props.patientIntakeProfile.email ?? ""}
        emergencyContactInfo={
          props.patientIntakeProfile.familyInformation === null
            ? {
                firstName: "",
                lastName: "",
                relationship: "",
                mobilePhoneNumber: "",
                homePhoneNumber: "",
              }
            : props.patientIntakeProfile.familyInformation[0]
        }
        onChangeEmergencyContact={(field, value) => handleEmergencyContactChange(field, value)}
        onChangePatientPhoneNumber={handlePatientPhoneNumberChange}
        onChangePatientEmail={(value) => props.onEditPatientIntakeProfile("email", value)}
      />

      <Divider variant="solid"></Divider>
      <IntakePatientProfileNotesSection
        notes={props.patientIntakeProfile.notes}
        onClickNewNote={() => props.onClickAddNewNote(props.patientIntakeProfile.id)}
        onClickMarkAsDone={props.onClickMarkNoteAsDone}
      />

      <Divider variant="solid"></Divider>
      <IntakeProfilePlanInfo
        isEditMode={props.isEditMode}
        currPlanName={props.patientIntakeProfile.plan?.name ?? "Unknown"}
        plans={props.intakePlans}
        planStartDate={props.patientIntakeProfile.planStartDate}
        planEnrollmentStatus={props.patientIntakeProfile.planEnrollmentStatus}
        UASStatus={props.patientIntakeProfile.UASCompleted}
        UASDate={props.patientIntakeProfile.UASAssessmentDate}
        UASHoursAmount={props.patientIntakeProfile.UASHoursAmount}
        onChangePlan={(plan) => props.onEditPatientIntakeProfile("plan", plan)}
        onChangeField={(field, value) => props.onEditPatientIntakeProfile(field, value)}
      />

      <Divider variant="solid"></Divider>
      <IntakeProfileNYIAInfo
        isEditMode={props.isEditMode}
        NYIA1Date={props.patientIntakeProfile.NYIA1AppoitmentDate}
        NYIA1Status={props.patientIntakeProfile.NYIA1Completed}
        NYIA2Date={props.patientIntakeProfile.NYIA2AppoitmentDate}
        NYIA2Status={props.patientIntakeProfile.NYIA2Completed}
        NYIAResults={props.patientIntakeProfile.NYIAResults}
        onChangeField={(field, value) => props.onEditPatientIntakeProfile(field, value)}
      />

      <Divider variant="solid"></Divider>
      <IntakePatientCommunicationLog communicationLog={props.communicationLog} />
    </Flex>
  );
};

export default IntakePatientProfileBody;
