import { Flex } from "@chakra-ui/react";
import React from "react";
import { match, P } from "ts-pattern";
import { Messages } from "../../../core/api";
import Dot02SIcon from "../../../shared/icons/Dot02SIcon";
import { fmap } from "../../../shared/utils";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";
import CallTicketText from "./CallTicket/helpers/CallTicketText";
import CallTicketIndexBadge from "./CallTicketIndexBadge";

export interface CallTicketPartiesActions {
  labe: string;
  onClick: () => void;
}

const roleTypeToText = {
  AgencyMember: "Agency Mmeber",
  Caregiver: "Caregiver",
  Patient: "Patient",
  NotIdentified: "Unknown Number",
} as const;

const CallTicketParticipantCard = (props: {
  role: Messages["CallSessionDetails"]["sessionParticipantRoles"][number];
  index: number;
  actions?: React.ReactNode;
}) => {
  return match(props)
    .with({ role: { type: P.union("Caregiver", "AgencyMember", "Patient") } }, (props) => (
      <CallTicketParticipantCard.Row
        index={props.index}
        name={`${props.role.firstName} ${props.role.lastName} ${
          "subscriber" in props.role ? fmap(props.role.subscriber, (e) => `(${e})`) : ""
        }`}
        description={
          <>
            <CallTicketText>{roleTypeToText[props.role.type]}</CallTicketText>
            <Dot02SIcon boxSize={8} />
            <CallTicketText>{props.role.role}</CallTicketText>
          </>
        }
        // actions={props.actions}
      />
    ))
    .with({ role: { type: "NotIdentified" } }, (props) => (
      <CallTicketParticipantCard.Row
        index={props.index}
        name={phoneFormatter.formatNationalIfValid(props.role.phoneNumber)}
        description={
          <>
            <CallTicketText>{roleTypeToText[props.role.type]}</CallTicketText>
            <Dot02SIcon boxSize={8} />
            <CallTicketText>{props.role.role}</CallTicketText>
            <Dot02SIcon boxSize={8} />
            <CallTicketText>
              {phoneFormatter.formatNationalIfValid(props.role.phoneNumber)}
            </CallTicketText>
          </>
        }
        // actions={props.actions}
      />
    ))
    .exhaustive();
};

CallTicketParticipantCard.Row = (props: {
  index: number;
  name: string;
  description: React.ReactNode;
  actions?: React.ReactNode;
}) => {
  return (
    <Flex direction={"row"} gap={2} alignItems={"center"}>
      <CallTicketIndexBadge>{props.index}</CallTicketIndexBadge>
      <Flex direction={"column"}>
        <CallTicketText fontWeight={"bold"}>{props.name}</CallTicketText>
        <Flex direction={"row"} color={"gray.400"} alignItems={"center"}>
          {props.description}
        </Flex>
      </Flex>
      {props.actions}
    </Flex>
  );
};

export default CallTicketParticipantCard;
