import React from "react";
import { createIcon } from "@chakra-ui/react";

const SmileyMehFillIcon = createIcon({
  displayName: "SmileyMehFillIcon",
  viewBox: "0 0 256 256",
  path: (
    <path
      fill="currentColor"
      d="M128 24a104 104 0 1 0 104 104A104.13 104.13 0 0 0 128 24ZM92 96a12 12 0 1 1-12 12a12 12 0 0 1 12-12Zm76 72H88a8 8 0 0 1 0-16h80a8 8 0 0 1 0 16Zm-4-48a12 12 0 1 1 12-12a12 12 0 0 1-12 12Z"
    />
  ),
});

export default SmileyMehFillIcon;
