import React from "react";
import { createIcon } from "@chakra-ui/react";

const AddIcon = createIcon({
  displayName: "AddIcon",
  viewBox: "0 0 24 24",
  path: <path fill="currentColor" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />,
});

export default AddIcon;
