import React from "react";
import { createIcon } from "@chakra-ui/react";

const BaselineMinusIcon = createIcon({
  displayName: "BaselineMinusIcon",
  viewBox: "0 0 24 24",
  path: <path fill="currentColor" d="M19 12.998H5v-2h14z" />,
});

export default BaselineMinusIcon;
