import React from "react";
import { createIcon } from "@chakra-ui/react";

const SmileyFillIcon = createIcon({
  displayName: "SmileyFillIcon",
  viewBox: "0 0 256 256",
  path: (
    <path
      fill="currentColor"
      d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24ZM92 96a12 12 0 1 1-12 12a12 12 0 0 1 12-12Zm82.92 60c-10.29 17.79-27.39 28-46.92 28s-36.63-10.2-46.92-28a8 8 0 1 1 13.84-8c7.47 12.91 19.21 20 33.08 20s25.61-7.1 33.08-20a8 8 0 1 1 13.84 8ZM164 120a12 12 0 1 1 12-12a12 12 0 0 1-12 12Z"
    />
  ),
});

export default SmileyFillIcon;
