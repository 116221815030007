import { EditIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import { EntityCardLink } from "../../../shared/components/EntityCard";
import SaveIcon from "../../../shared/icons/SaveIcon";
import { PhoneIcon } from "@chakra-ui/icons";
import IntakeNextCallBadge from "./IntakeNextCallBadge";
import PhoneNumberDialer from "../../../shared/components/PhoneNumberDialer";
import { phoneFormatter } from "../../../shared/utils/phone-formatter";

interface Props {
  patientInfo: Messages["IntakePatientProfile"];
  isEditMode: boolean;
  onCloseIntakeProfile: () => void;
  onClickEdit: (newValue: boolean) => void;
}

export const IntakeProfileHeader = (props: Props) => {
  const { firstName, lastName, displayId, id, gender, nextCallDate, phoneNumbers } =
    props.patientInfo;
  const phoneNumberDialerDisclosure = useDisclosure();

  const patientFullName = `${firstName} ${lastName} ${displayId !== null ? `(${displayId})` : ""}`;

  return (
    <>
      <Flex justifyContent="space-between" marginTop="15px" alignItems={"center"}>
        <Flex gap={3}>
          <EntityCardLink
            boxProps={{ fontSize: "xl", marginBottom: "5px", p: 0 }}
            entity={{
              type: "Patient",
              id: id,
              displayId: displayId,
              gender: gender,
              fullName: patientFullName,
              status: "DRAFT",
            }}
            onBeforeClick={props.onCloseIntakeProfile}
          />
          <IntakeNextCallBadge nextCallDate={nextCallDate} />
        </Flex>
        <Flex direction={"row-reverse"}>
          <Box>
            {props.isEditMode ? (
              <Button
                leftIcon={<SaveIcon />}
                colorScheme="blue"
                fontSize="12px"
                onClick={() => props.onClickEdit(false)}
              >
                Save
              </Button>
            ) : (
              <Button
                leftIcon={props.isEditMode ? <SaveIcon /> : <EditIcon />}
                variant="ghost"
                color="blue.500"
                fontSize="12px"
                onClick={() => props.onClickEdit(true)}
              >
                Edit
              </Button>
            )}
          </Box>
          <Box>
            <Button
              leftIcon={<PhoneIcon />}
              variant="ghost"
              color="blue.500"
              fontSize="12px"
              disabled={phoneNumbers.length === 0}
              onClick={phoneNumberDialerDisclosure.onOpen}
            >
              Call
            </Button>
            {phoneNumberDialerDisclosure.isOpen && (
              <PhoneNumberDialer
                predefinedPhoneNumber={phoneFormatter.formatNumberWithoutCountryPrefix(
                  phoneNumbers[0].phonenumber
                )}
                onClose={phoneNumberDialerDisclosure.onClose}
              />
            )}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default IntakeProfileHeader;
