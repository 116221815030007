import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { match } from "ts-pattern";
import { z } from "zod";
import { Messages } from "../../../../core/api";
import ErrorPage from "../../../../shared/components/ErrorPage";
import useApi from "../../../../shared/hooks/useApi";
import usePathParams from "../../../../shared/hooks/usePathParams";
import { queryKeys } from "../../../../shared/query-keys";
import { WorkflowTaskInstanceId } from "../../../../shared/schema/schema";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";
import { zPathParamId } from "../../../../shared/utils/zod";
import { WorkflowInputForm } from "../../components/WorkflowForm";

const zPathParams = z.object({
  id: zPathParamId(WorkflowTaskInstanceId).optional(),
});

export default function WorkflowTaskDrawer() {
  const { id } = usePathParams(zPathParams);
  const { stateService } = useRouter();
  const handleClose = () => {
    stateService.go("app.workflow_tasks");
  };

  return (
    <>
      <Drawer placement="right" isOpen={id !== undefined} onClose={handleClose} size="lg">
        <DrawerOverlay />
        {id !== undefined && <InnerDrawer id={id} />}
      </Drawer>
    </>
  );
}

function InnerDrawer(props: { id: WorkflowTaskInstanceId }) {
  const queryClient = useQueryClient();
  const { api } = useApi();
  const [selectedOutput, setSelectedOutput] = React.useState<string | null>(null);
  const toast = useToast();
  const { stateService } = useRouter();

  const query = useQuery({
    queryKey: queryKeys.workflowTask.item(props.id),
    select: (data) => data.item,
    queryFn: () =>
      api.get("./workflow_tasks/:workflowTaskInstanceId", {
        path: {
          workflowTaskInstanceId: props.id,
        },
      }),
  });

  const submit = useMutation({
    mutationFn: (body: { outputKey: string; data: Record<string, unknown> }) =>
      api.post("./workflow_tasks/:workflowTaskInstanceId/submit", {
        path: {
          workflowTaskInstanceId: props.id,
        },
        body: {
          outputKey: body.outputKey,
          data: body.data,
        },
      }),
    onSuccess: () => {
      toast({ title: "Task submitted!", position: "top-right" });
      queryClient.invalidateQueries(queryKeys.workflowTask.search.K);
      stateService.go("app.workflow_tasks", undefined, { location: "replace" });
    },
    onError: (error) => {
      toast({
        title: "Error in fetching communication center labels",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  if (query.isLoading) {
    return (
      <DrawerContent>
        <Center flexDirection="column" gap={8} flex={1} color="gray.500">
          <Spinner size="xl" />
        </Center>
      </DrawerContent>
    );
  }

  if (query.isError) {
    return (
      <DrawerContent>
        <ErrorPage error={new Error(`${query.error}`)} resetErrorBoundary={query.refetch} />;
      </DrawerContent>
    );
  }

  const { data: task } = query;

  switch (task.template.layout) {
    case "call-script":
    case "document-review":
      return (
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">{task.meta.name}</DrawerHeader>
          <DrawerBody>
            <p>Not implemented</p>
          </DrawerBody>
        </DrawerContent>
      );
    case "manual":
      return (
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">{task.meta.name}</DrawerHeader>
          <DrawerBody>
            <Flex direction="column" gap={3}>
              <Box p={8} mt={4} bg="gray.50" rounded="lg">
                <Text fontSize="lg" fontWeight="semibold">
                  Instructions:
                </Text>
                <Text fontSize="lg" whiteSpace="pre-wrap">
                  {task.template.data.instructions}
                </Text>
              </Box>

              {Object.entries(task.output).map(([key, form]) => (
                <Box
                  key={key}
                  role="button"
                  border="2px"
                  rounded="lg"
                  borderColor={selectedOutput === key ? "blue.400" : "gray.200"}
                  onClick={() => setSelectedOutput(key)}
                  tabIndex={0}
                  transition="var(--chakra-transition-duration-normal)"
                  _hover={{
                    bg: selectedOutput === key ? "" : "gray.50",
                  }}
                >
                  <Text fontSize="lg" fontWeight="semibold" p={4}>
                    {key}
                  </Text>

                  {selectedOutput === key && (
                    <Box p={4} borderTop="1px" borderTopColor="gray.200">
                      <WorkflowInputForm
                        fields={mapWorkflowDataFieldRecordToInput(form)}
                        onSubmit={(data) => submit.mutate({ outputKey: key, data: data })}
                      />
                    </Box>
                  )}
                </Box>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      );
    default:
      return match(task.template).exhaustive();
  }
}

function mapWorkflowDataFieldRecordToInput(
  v: Record<string, Messages["WorkflowDataFieldType"]>
): Map<string, Messages["WorkflowInput"]> {
  const map = new Map();

  for (const [key, value] of Object.entries(v)) {
    map.set(key, { name: key, ...value });
  }

  return map;
}
