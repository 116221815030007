import { CaregiverId, VisitBroadcastId } from "../../../../scripts/messages/ids";
import { VisitBroadcastService } from "../../visitBroadcast.service";
import { VisitAssignmentRequest } from "../visit-table-common.types";
import { CaregiverVisitsRequestsTableNonMultipleBindings } from "./caregivers-visits-assignment-requests-table-non-multiple.types";

//! @ngInject
class CaregiversVisitsAssignmentRequestsTableNonMultipleCtrl
  implements ng.IComponentController, CaregiverVisitsRequestsTableNonMultipleBindings
{
  visitAssignmentRequests!: VisitAssignmentRequest[];
  visitBroadcastId!: VisitBroadcastId;
  startFlyToCaregiver!: (caregiverId: CaregiverId) => void;
  stopFlyToCaregiver!: () => void;
  updateEngagements!: () => void;
  closeModal!: () => void;

  constructor(
    private $rootScope: ng.IRootScopeService,
    private toaster: toaster.IToasterService,
    private mfModal: any,
    private visitBroadcastService: VisitBroadcastService
  ) {}

  assignToCaregiver(caregiverId: CaregiverId) {
    return this.visitBroadcastService
      .confirmCaregiverAssignmentRequest(this.visitBroadcastId, caregiverId)
      .then(
        (res) => {
          if (res.data.assignWithIncreasedCaregiverOvertime) {
            this.toaster.pop({
              type: "warning",
              title: "Warning",
              body: `Successfully assigned caregiver with increased caregiver overtime`,
            });
          } else {
            this.toaster.pop("success", "Successfully assigned caregiver");
          }

          this.$rootScope.$emit("refresh_visits");
          this.$rootScope.$emit("visit_changed", { visitId: this.visitBroadcastId });
          this.closeModal();
        },
        (err) => {
          let errorMessage = "Failed to assign caregiver";
          if (err.status === 403) {
            errorMessage = "Not permitted to increase caregiver overtime.";
          }
          this.toaster.pop("error", "Oops...", errorMessage);
        }
      );
  }

  openShouldAssignModal(caregiverId: CaregiverId) {
    const modal = this.mfModal.create({
      subject: "Are You Sure?",
      variant: "warning",
      message: "Are you sure you want to confirm manually?",
      cancelLabel: "I changed my mind",
      confirmLabel: "Confirm assignment",
      showInput: false,
      layoutOrder: ["message"],
      hideCancelButton: false,
      preventBackdropClose: true,
      onConfirm: async () => {
        await this.assignToCaregiver(caregiverId);
        modal.close();
      },
    });
  }

  rejectAssignmentToCaregiver(caregiverId: CaregiverId) {
    return this.visitBroadcastService
      .rejectCaregiverAssignmentRequest(this.visitBroadcastId, caregiverId)
      .then(
        () => {
          this.toaster.pop("success", "Successfully rejected caregiver's request.");

          this.$rootScope.$emit("refresh_visits");
          this.$rootScope.$emit("visit_changed", { visitId: this.visitBroadcastId });
        },
        (err) => {
          this.toaster.pop("error", "Oops...", "Failed to reject the caregiver's request.", err);
        }
      );
  }

  openShouldRejectModal(caregiverId: CaregiverId) {
    const modal = this.mfModal.create({
      subject: "Are You Sure?",
      variant: "warning",
      message: "Are you sure you want to reject assignment?",
      cancelLabel: "I changed my mind",
      confirmLabel: "Confirm",
      showInput: false,
      layoutOrder: ["message"],
      hideCancelButton: false,
      preventBackdropClose: true,
      onConfirm: async () => {
        await this.rejectAssignmentToCaregiver(caregiverId);
        modal.close();
      },
    });
  }
}

export const caregiversVisitsAssignmentRequestsTableNonMultiple = {
  templateUrl:
    "admin/modules/visit-broadcast/components/caregivers-visits-assignment-requests-table-non-multiple/caregivers-visits-assignment-requests-table-non-multiple.component.html",
  controller: CaregiversVisitsAssignmentRequestsTableNonMultipleCtrl,
  controllerAs: "ctrl",
  bindings: {
    visitAssignmentRequests: "<",
    visitBroadcastType: "<",
    closeModal: "&",
    visitInstances: "<",
    visitBroadcastId: "<",
    updateEngagements: "<",
    startFlyToCaregiver: "<",
    stopFlyToCaregiver: "<",
  },
};
