import { Flex } from "@chakra-ui/react";
import React from "react";
import { FaPhoneSlash } from "react-icons/fa";
import { dateFormatter } from "../../../../../../../shared/utils/date-formatter";
import CallTicketIndexBadge from "../../../../CallTicketIndexBadge";
import CallTicketText from "../../CallTicketText";
import { getParticipantDescription } from "../call-ticket-event-utils";
import { CallLeaveEvent } from "../call-ticket-events-typings";

interface Props {
  event: CallLeaveEvent;
}

const CallTicketEventCardLeave = ({ event }: Props) => {
  return (
    <Flex gap={2} alignItems={"center"}>
      <CallTicketIndexBadge>
        <FaPhoneSlash />
      </CallTicketIndexBadge>
      <Flex direction={"column"}>
        <CallTicketText color={"gray.400"}>
          {dateFormatter.toDateTime(event.createdAt)}
        </CallTicketText>
        <Flex gap={1}>
          <CallTicketText fontWeight={"bold"}>
            {getParticipantDescription(event.callSessionParticipant)}
          </CallTicketText>
          <CallTicketText>left the call.</CallTicketText>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CallTicketEventCardLeave;
