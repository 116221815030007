import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Switch,
  Tag,
  Textarea,
} from "@chakra-ui/react";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import { capitalize } from "lodash";
import React from "react";
import { match } from "ts-pattern";
import { z } from "zod";
import { zj } from "zod-joda";
import { Messages } from "../../../core/api";
import { AddButton } from "./AddButton";
import { MinusButton } from "./MinusButton";
import { RequiredAst } from "./RequiredAst";

const BooleanDataTypeFormControl = (props: {
  input: Messages["WorkflowBooleanField"] & { name: string };
  value: (boolean | null)[];
  errors: string[];
  onChange: (value: (boolean | null)[]) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    props.onChange(props.value.map((value, i) => (i === index ? e.target.checked : value)));
  };

  return (
    <WorkflowInputField
      label={props.input.name}
      value={props.value}
      input={props.input}
      errors={props.errors}
      renderField={(value, index) => (
        <Switch size="lg" isChecked={value === true} onChange={(e) => handleChange(e, index)} />
      )}
      onChange={props.onChange}
    />
  );
};

const WorkflowInputField = <$Value,>(props: {
  label: React.ReactNode;
  value: ($Value | null)[];
  errors: string[];
  input: { array?: boolean; nullable?: boolean; optional?: boolean };
  renderField: (value: $Value | null, index: number) => React.ReactNode;
  onChange: (value: ($Value | null)[]) => void;
}) => {
  if (props.input.array === true) {
    return <ArrayWorkflowInputField {...props} />;
  }

  return (
    <SingleWorkflowInputField
      label={props.label}
      value={props.value[0]}
      input={props.input}
      errors={props.errors}
      renderField={(value) => props.renderField(value ?? null, 0)}
      onChange={(v) => props.onChange(v === undefined ? [] : [v])}
    />
  );
};

const SingleWorkflowInputField = <$Value,>(props: {
  label: React.ReactNode;
  value: $Value | null;
  input: { array?: boolean; nullable?: boolean; optional?: boolean };
  errors: string[];
  renderField: (value: $Value | null) => React.ReactNode;
  onChange: (value: $Value | null) => void;
}) => {
  const isRequired = props.input.optional !== true && props.input.nullable !== true;

  return (
    <FormControl isInvalid={props.errors.length > 0}>
      <FormLabel>
        {props.label}
        {isRequired && <RequiredAst />}:
      </FormLabel>
      {props.renderField(props.value)}
      {props.errors.length > 0 && <FormErrorMessage>{props.errors[0]}</FormErrorMessage>}
    </FormControl>
  );
};

const ArrayWorkflowInputField = <$Value,>(props: {
  label: React.ReactNode;
  value: ($Value | null)[];
  input: { array?: boolean; nullable?: boolean; optional?: boolean };
  errors: string[];
  renderField: (value: $Value | null, index: number) => React.ReactNode;
  onChange: (value: ($Value | null)[]) => void;
}) => {
  const isRequired = props.input.optional !== true && props.input.nullable !== true;

  return (
    <FormControl isInvalid={props.errors.length > 0}>
      <FormLabel>
        {props.label}
        {isRequired && <RequiredAst />}:
      </FormLabel>
      <Flex direction="column" gap={2}>
        {props.value.map((value, index) => (
          <Flex key={index} gap={2} align="center" justify="space-between">
            {props.renderField(value, index)}
            {index === 0 ? (
              <AddButton onClick={() => props.onChange([...props.value, null])} />
            ) : (
              <MinusButton
                onClick={() => props.onChange(props.value.filter((_, i) => i !== index))}
              />
            )}
          </Flex>
        ))}
      </Flex>
      {props.errors.length > 0 && <FormErrorMessage>{props.errors[0]}</FormErrorMessage>}
    </FormControl>
  );
};

const DateDataTypeFormControl = (props: {
  input: Messages["WorkflowDateField"] & { name: string };
  value: (LocalDate | null)[];
  errors: string[];
  onChange: (value: (LocalDate | null)[]) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const v = e.target.value === "" ? null : LocalDate.parse(e.target.value);
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  return (
    <WorkflowInputField
      label={props.input.name}
      value={props.value}
      input={props.input}
      errors={props.errors}
      renderField={(value, index) => (
        <Input
          type="date"
          value={value?.toString() ?? ""}
          onChange={(e) => handleChange(e, index)}
        />
      )}
      onChange={props.onChange}
    />
  );
};

const DateTimeDataTypeFormControl = (props: {
  input: Messages["WorkflowDateTimeField"] & { name: string };
  value: (LocalDateTime | null)[];
  errors: string[];
  onChange: (value: (LocalDateTime | null)[]) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const v = e.target.value === "" ? null : LocalDateTime.parse(e.target.value);
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  return (
    <WorkflowInputField
      label={props.input.name}
      value={props.value}
      input={props.input}
      errors={props.errors}
      renderField={(value, index) => (
        <Input
          type="datetime-local"
          value={value?.toString() ?? ""}
          onChange={(e) => handleChange(e, index)}
        />
      )}
      onChange={props.onChange}
    />
  );
};

const EntityDataTypeFormControl = (props: {
  input: Messages["WorkflowEntityField"] & { name: string };
  value: (number | null)[];
  errors: string[];
  onChange: (value: (number | null)[]) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const v = e.target.value === "" ? null : parseInt(e.target.value, 10);
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  const name = (
    <>
      {props.input.name} <Tag>{capitalize(props.input.entity)}</Tag>
    </>
  );

  return (
    <WorkflowInputField
      label={name}
      value={props.value}
      input={props.input}
      errors={props.errors}
      renderField={(value, index) => (
        <Input
          type="number"
          value={value?.toString() ?? ""}
          onChange={(e) => handleChange(e, index)}
        />
      )}
      onChange={props.onChange}
    />
  );
};

const NumberDataTypeFormControl = (props: {
  input: Messages["WorkflowNumberField"] & { name: string };
  value: (number | null)[];
  errors: string[];
  onChange: (value: (number | null)[]) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const v = e.target.value === "" ? null : parseInt(e.target.value, 10);
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  return (
    <WorkflowInputField
      label={props.input.name}
      value={props.value}
      input={props.input}
      errors={props.errors}
      renderField={(value, index) => (
        <Input
          type="number"
          value={value?.toString() ?? ""}
          onChange={(e) => handleChange(e, index)}
        />
      )}
      onChange={props.onChange}
    />
  );
};

const TextDataTypeFormControl = (props: {
  input: Messages["WorkflowTextField"] & { name: string };
  value: (string | null)[];
  errors: string[];
  onChange: (value: (string | null)[]) => void;
}) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const v = e.target.value === "" ? null : e.target.value;
    props.onChange(props.value.map((value, i) => (i === index ? v : value)));
  };

  return (
    <WorkflowInputField
      label={props.input.name}
      value={props.value}
      input={props.input}
      errors={props.errors}
      renderField={(value, index) => {
        return props.input.type === "textarea" ? (
          <Textarea value={value?.toString() ?? ""} onChange={(e) => handleChange(e, index)} />
        ) : (
          <Input
            type="text"
            value={value?.toString() ?? ""}
            onChange={(e) => handleChange(e, index)}
          />
        );
      }}
      onChange={props.onChange}
    />
  );
};

type WorkflowInputProps<$Input, $Type = unknown> = {
  input: $Input;
  value: ($Type | null)[];
  onChange: (value: ($Type | null)[]) => void;
};

type BaseWorkflowInputProps = {
  input: { type: Messages["WorkflowInput"]["type"] };
  value: unknown[];
  onChange: unknown;
};

type InputMapper = {
  entity: {
    input: Messages["WorkflowEntityField"] & { name: string };
    type: number;
  };
  text: {
    input: Messages["WorkflowTextField"] & { name: string };
    type: string;
  };
  textarea: {
    input: Messages["WorkflowTextField"] & { name: string };
    type: string;
  };
  number: {
    input: Messages["WorkflowNumberField"] & { name: string };
    type: number;
  };
  boolean: {
    input: Messages["WorkflowBooleanField"] & { name: string };
    type: boolean;
  };
  date: {
    input: Messages["WorkflowDateField"] & { name: string };
    type: LocalDate;
  };
  datetime: {
    input: Messages["WorkflowDateTimeField"] & { name: string };
    type: LocalDateTime;
  };
};

function isInputTypeOf<T extends keyof InputMapper>(
  p: BaseWorkflowInputProps,
  v: T
): p is WorkflowInputProps<InputMapper[T]["input"], InputMapper[T]["type"]> {
  return p.input.type === v;
}

export const DataTypeFormControl = (
  props: Omit<BaseWorkflowInputProps, "onChange"> & {
    errors: string[];
    onChange: (value: unknown[]) => void;
  }
) => {
  if (isInputTypeOf(props, "boolean")) {
    return <BooleanDataTypeFormControl {...props} />;
  }

  if (isInputTypeOf(props, "date")) {
    return <DateDataTypeFormControl {...props} />;
  }

  if (isInputTypeOf(props, "datetime")) {
    return <DateTimeDataTypeFormControl {...props} />;
  }

  if (isInputTypeOf(props, "entity")) {
    return <EntityDataTypeFormControl {...props} />;
  }

  if (isInputTypeOf(props, "number")) {
    return <NumberDataTypeFormControl {...props} />;
  }

  if (isInputTypeOf(props, "text")) {
    return <TextDataTypeFormControl {...props} />;
  }

  if (isInputTypeOf(props, "textarea")) {
    return <TextDataTypeFormControl {...props} />;
  }

  return <></>;
};

function createWorkflowDataFieldsRecordParser<
  $Schema extends Map<string, Messages["WorkflowDataFieldType"]>
>(schema: $Schema) {
  const zObjectProperyValidators = {};

  for (const [key, property] of schema.entries()) {
    const type = property.type === "option" ? property.optionType.type : property.type;

    let zPropertyValidator: z.ZodTypeAny = match(type)
      .with("boolean", () => z.boolean())
      .with("date", () => zj.localDate())
      .with("datetime", () => zj.localDateTime())
      .with("entity", () => z.number())
      .with("number", () => z.number())
      .with("text", () => z.string())
      .with("textarea", () => z.string())
      .exhaustive();

    if (property.array === true) {
      zPropertyValidator = zPropertyValidator.array();
    }

    if (property.optional === true) {
      zPropertyValidator = zPropertyValidator.optional();
    }

    if (property.nullable === true) {
      zPropertyValidator = zPropertyValidator.nullable();
    }

    Object.assign(zObjectProperyValidators, { [key]: zPropertyValidator });
  }

  return z.object(zObjectProperyValidators);
}

export const WorkflowInputForm = (props: {
  fields: Map<string, Messages["WorkflowInput"]>;
  onSubmit: (values: Record<string, unknown>) => void;
}) => {
  const [values, setValues] = React.useState<Record<string, unknown[]>>(() => {
    return [...props.fields.keys()].reduce((acc, key) => {
      acc[key] = [null];
      return acc;
    }, {} as Record<string, unknown[]>);
  });

  const [errors, setErrors] = React.useState<Record<string, string[]>>({});

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const parsedFields = Object.entries(values).reduce((acc, [key, value]) => {
      const input = props.fields.get(key);
      const newValue = input?.array === true ? value : value[0];
      acc[key] = newValue;
      return acc;
    }, {} as Record<string, unknown>);

    const result = createWorkflowDataFieldsRecordParser(props.fields).safeParse(parsedFields);

    if (!result.success) {
      return setErrors(result.error.flatten().fieldErrors);
    }

    return props.onSubmit(result.data);
  };

  const handleChange = (key: string, value: unknown[]) => {
    setValues((prev) => ({ ...prev, [key]: value }));
    setErrors((prev) => ({ ...prev, [key]: [] }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap={4}>
        {[...props.fields.entries()].map(([key, value]) => (
          <DataTypeFormControl
            key={key}
            input={value}
            value={values[key] ?? []}
            errors={errors[key] ?? []}
            onChange={(v) => handleChange(key, v)}
          />
        ))}
        <Button type="submit" variant="solid" colorScheme="blue" ms="auto">
          Submit
        </Button>
      </Flex>
    </form>
  );
};
