import { WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { FaPhoneSlash, FaVoicemail } from "react-icons/fa";
import { match, P } from "ts-pattern";
import { Messages } from "../../../core/api";
import CompletedSolidIcon from "../../../shared/icons/CompletedSolidIcon";
import useCommCenterCallRecording from "../hooks/useCommCenterCallRecording";
import CallTicketRecording from "./CallTicket/helpers/CallTicketEventCard/CallTicketRecording";
import CallTicketText from "./CallTicket/helpers/CallTicketText";

const PhoneCallStatus = ({
  call,
  recordingState,
}: {
  call: Messages["CallSessionDetails"];
  recordingState: ReturnType<typeof useCommCenterCallRecording>;
}) => {
  return match(call)
    .with({ callResult: "COMPLETE" }, () => (
      <Flex alignItems={"center"} gap={4}>
        <Icon as={CompletedSolidIcon} color={"green"} />
        <CallTicketText>Completed Call</CallTicketText>
        <Flex alignItems={"inherit"} gap={1} onClick={(e) => e.stopPropagation()}>
          <CallTicketRecording state={recordingState} />
        </Flex>
      </Flex>
    ))
    .with({ callResult: "FAIL" }, () => (
      <Flex gap={4} color={"red"} alignItems={"center"}>
        <WarningTwoIcon />
        <CallTicketText>Failed Call</CallTicketText>
      </Flex>
    ))
    .with({ callResult: "MISSED_CALL" }, () => (
      <Flex gap={4} color={"red"} alignItems={"center"}>
        <FaPhoneSlash />
        <CallTicketText>Missed Call</CallTicketText>
      </Flex>
    ))
    .with({ callResult: "RETURN_CALL" }, () => <Flex>Return Call</Flex>)
    .with({ callResult: "VOICE_MAIL" }, () => (
      <Flex color={"yellow.400"} gap={4} alignItems={"center"}>
        <FaVoicemail size={"16px"} />
        <CallTicketText>Voice Mail</CallTicketText>
      </Flex>
    ))
    .with({ callResult: "NO_ANSWER" }, () => (
      <Flex gap={4} color={"red"} alignItems={"center"}>
        <FaPhoneSlash />
        <CallTicketText>No Answer</CallTicketText>
      </Flex>
    ))
    .with({ callResult: P.nullish }, () => (
      <Flex>
        <CallTicketText>No Status</CallTicketText>
      </Flex>
    ))
    .exhaustive();
};

export default PhoneCallStatus;
