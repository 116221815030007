import React from "react";
import { ChronoUnit, LocalDate, LocalDateTime } from "@js-joda/core";
import { Button } from "@chakra-ui/react";
import { PhoneIcon } from "@chakra-ui/icons";

interface Props {
  nextCallDate: LocalDateTime | null;
}

const IntakeNextCallBadge = (props: Props) => {
  const getNextCallStyleProps = () => {
    if (props.nextCallDate === null) {
      return {
        text: "No call was scheduled",
        badgeColor: "gray",
      };
    } else {
      if (props.nextCallDate.isBefore(LocalDateTime.now())) {
        return {
          text: "Due date has passed",
          badgeColor: "red",
        };
      } else {
        const nextCallFromNowDays = LocalDate.now().until(
          props.nextCallDate.toLocalDate(),
          ChronoUnit.DAYS
        );
        const daysText = nextCallFromNowDays === 1 ? "day" : "days";
        const text =
          nextCallFromNowDays === 0 ? `Due Today` : `Due in ${nextCallFromNowDays} ${daysText}`;

        return {
          text,
          badgeColor: nextCallFromNowDays === 0 ? "yellow" : "whatsapp",
        };
      }
    }
  };

  const nextCallBadgeProps = getNextCallStyleProps();

  return (
    <Button
      marginTop="3px"
      padding="10px"
      height="25px"
      fontSize="12px"
      colorScheme={nextCallBadgeProps.badgeColor}
      boxShadow="none"
      cursor="default"
      _hover={{ colorScheme: nextCallBadgeProps.badgeColor }}
      _active={{ colorScheme: nextCallBadgeProps.badgeColor }}
      leftIcon={<PhoneIcon />}
      onClick={(event) => event.preventDefault()}
    >
      {nextCallBadgeProps.text}
    </Button>
  );
};

export default IntakeNextCallBadge;
