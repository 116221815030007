import React from "react";
import { Messages } from "../../../../core/api";
import { fmap } from "../../../../shared/utils";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import styled from "@emotion/styled";
import IntakeDashboardNextCallBadge from "./IntakeDashboardNextCallBadge";
import { PatientId } from "../../../../shared/schema/schema";
import { Text } from "@chakra-ui/react";

interface IntakeDashboardCardProps {
  patient: Messages["IntakePatientDashboardDetails"];
  onClickPatientCard: (patientId: PatientId) => void;
}

const IntakeDashboardCard = (props: IntakeDashboardCardProps) => {
  const { patient } = props;

  const patientTitle = `${patient.firstName} ${patient.lastName} (${
    patient.displayId ?? patient.id
  })`;
  const lastCallDate = fmap(patient.lastCall, (v) => dateFormatter.toDate(v)) ?? "None";

  return (
    <IntakeDashboardCard.Root onClick={() => props.onClickPatientCard(patient.id)}>
      <IntakeDashboardCard.Section>
        <Text as="b">{patientTitle}</Text>
        <IntakeDashboardNextCallBadge nextCallDate={patient.nextCall} />
      </IntakeDashboardCard.Section>

      <IntakeDashboardCard.Section>
        <Text>
          {patient.medicaidId ? (
            <IntakeDashboardCard.MediContainer>
              {"Medicaid"}
              <CheckIcon boxSize={4} color="green.300" />
            </IntakeDashboardCard.MediContainer>
          ) : (
            <IntakeDashboardCard.MediContainer>
              {"No Medicaid"}
              <CloseIcon boxSize={4} color="red.500" />
            </IntakeDashboardCard.MediContainer>
          )}
        </Text>
        <Text>
          {patient.medicareStatus === "Eligible" ? (
            <IntakeDashboardCard.MediContainer>
              {"Medicare"}
              <CheckIcon boxSize={4} color="green.300" />
            </IntakeDashboardCard.MediContainer>
          ) : (
            <IntakeDashboardCard.MediContainer>
              {"No Medicare"}
              <CloseIcon boxSize={4} color="red.500" />
            </IntakeDashboardCard.MediContainer>
          )}
        </Text>
      </IntakeDashboardCard.Section>

      <IntakeDashboardCard.Section>
        <Text>{`Last call: ${lastCallDate}`}</Text>
        <Text>{patient.plan?.code ? `Plan Code: ${patient.plan.code}` : "No plan"}</Text>
        {/* <Text>Website lead</Text> */}
      </IntakeDashboardCard.Section>
    </IntakeDashboardCard.Root>
  );
};

IntakeDashboardCard.Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  border-width: 2px;
  border-radius: 10px;
  padding: 15px;
  margin-top: 4px;
  max-width: 370px;
  min-width: 370px;
  max-height: 130px;
  min-height: 130px;

  cursor: pointer;
  :hover {
    background-color: var(--chakra-colors-gray-100);
  }
`;

IntakeDashboardCard.Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--chakra-space-1);
`;

IntakeDashboardCard.MediContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

export default IntakeDashboardCard;
