import { useQuery } from "@tanstack/react-query";
import React from "react";
import { QueryParamsOf } from "../../../../core/api";
import LoadingPage from "../../../../shared/components/LoadingPage";
import useAgencyMembers from "../../../../shared/hooks/useAgencyMembers";
import useApi from "../../../../shared/hooks/useApi";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import { useQueryParams } from "../../../../shared/hooks/useQueryParams";
import { queryKeys } from "../../../../shared/query-keys";
import WorkflowTaskDrawer from "./WorkflowTaskDrawer";
import WorkflowTasksPage from "./WorkflowTasksPage";

export default function WorkflowTasksRoute() {
  const { api } = useApi();
  const { agencyMember } = useAuthData();
  const agencyMemberQuery = useAgencyMembers();

  const queryParams = useQueryParams<QueryParamsOf<"get", "./workflow_tasks/tasks">>({
    storageKey: ["workflow", "tasks-table"],
    defaultOptions: {
      assignee: [agencyMember.id],
    },
  });

  const tasksQuery = useQuery({
    queryKey: queryKeys.workflowTask.search(queryParams.params),
    queryFn: () => {
      return api.get("./workflow_tasks/tasks", {
        query: queryParams.params.toJSON(),
      });
    },
    select: (data) => data.tasks,
  });

  const skillsQuery = useQuery({
    queryKey: queryKeys.workflowTask.skills(),
    staleTime: Infinity,
    queryFn: () => api.get("./workflow_tasks/skills", {}),
    select: (data) => data.skills,
  });

  if (tasksQuery.isLoading || agencyMemberQuery.isLoading || skillsQuery.isLoading) {
    return <LoadingPage />;
  }

  if (tasksQuery.isError || agencyMemberQuery.isError || skillsQuery.isError) {
    return <div>Error</div>;
  }

  return (
    <>
      <WorkflowTasksPage
        isRefetching={tasksQuery.isRefetching}
        tasks={tasksQuery.data}
        agencyMembers={agencyMemberQuery.data}
        skills={skillsQuery.data}
        filters={queryParams}
        onChangeFilter={queryParams.setValue}
        onChangeFilters={queryParams.setValues}
      />
      <WorkflowTaskDrawer />
    </>
  );
}
