import React from "react";
import { ChronoUnit, LocalDate, LocalDateTime } from "@js-joda/core";
import { Button } from "@chakra-ui/react";
import { PhoneIcon } from "@chakra-ui/icons";

interface Props {
  nextCallDate: LocalDateTime | null;
}

const getNextCallStyleProps = (nextCallDate: LocalDateTime | null) => {
  if (nextCallDate === null) {
    return {
      text: "Not scheduled",
      badgeColor: "blackAlpha",
    };
  } else {
    if (nextCallDate.isBefore(LocalDateTime.now())) {
      const nextCallPassedNowDays = nextCallDate
        .toLocalDate()
        .until(LocalDate.now(), ChronoUnit.DAYS);

      const text =
        nextCallPassedNowDays === 0
          ? `Today`
          : nextCallPassedNowDays === 1
          ? `Yesterday`
          : `${nextCallPassedNowDays} days ago`;
      return {
        text,
        badgeColor: "red",
      };
    } else {
      const nextCallFromNowDays = LocalDate.now().until(
        nextCallDate.toLocalDate(),
        ChronoUnit.DAYS
      );
      const text =
        nextCallFromNowDays === 0
          ? `Today`
          : nextCallFromNowDays === 1
          ? "Tomrrow"
          : `In ${nextCallFromNowDays} days`;

      return {
        text,
        badgeColor: nextCallFromNowDays === 0 ? "yellow" : "whatsapp",
      };
    }
  }
};

const IntakeDashboardNextCallBadge = (props: Props) => {
  const nextCallBadgeProps = getNextCallStyleProps(props.nextCallDate);

  return (
    <Button
      marginTop="3px"
      padding="10px"
      height="25px"
      fontSize="12px"
      colorScheme={nextCallBadgeProps.badgeColor}
      boxShadow="none"
      cursor="default"
      _hover={{ colorScheme: nextCallBadgeProps.badgeColor }}
      _active={{ colorScheme: nextCallBadgeProps.badgeColor }}
      leftIcon={<PhoneIcon />}
      onClick={(event) => event.preventDefault()}
    >
      {nextCallBadgeProps.text}
    </Button>
  );
};

export default IntakeDashboardNextCallBadge;
