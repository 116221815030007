import { ArrowDownIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  SystemStyleObject,
  Table,
  TableCellProps,
  TableRowProps,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { getByPath, Path, PathValue } from "dot-path-value";
import { Instant, LocalDate, LocalDateTime } from "@js-joda/core";
import React from "react";
import { FaEye } from "react-icons/fa";
import invariant from "tiny-invariant";
import { dateFormatter } from "../../../shared/utils/date-formatter";

export enum ZIndex {
  _minus = -1,
  _zero = 0,
  DataTableTd,
  DataTableTh,
  DataTableStickyTh,
  DataTableHead,
  Menu,
}

export type DataTableProps<Data> = {
  data: Data[];
  defaultOptions?: {
    sort?: {
      column: Path<Data>;
      direction: "asc" | "desc";
    };
  };
  filters?: React.ReactNode;
  trProps?: (row: Data) => TableRowProps;
  tdProps?: (p: { id: Path<Data>; row: Data }) => TableCellProps;
  columns: {
    [Property in Path<Data>]: TableColumn<Data, Property>;
  }[Path<Data>][];
  onClickRow?: (row: Data, options: { e: React.MouseEvent; isNewTab: boolean }) => void;
};

type TableColumn<Data, Property extends Path<Data> = Path<Data>> = {
  id: Property;
  label?: string;
  transform?: (value: PathValue<Data, Property>, row: Data) => React.ReactNode;
  sortFn?: (a: PathValue<Data, Property>, b: PathValue<Data, Property>) => number;
  placeholder?: React.ReactNode;
  sortable?: boolean;
  sticky?: "left" | "right";
};

type ActiveSort<Data> = {
  column: TableColumn<Data>;
  direction: "asc" | "desc";
};

function useSortDataTable<Data>(options?: { defaultSort?: ActiveSort<Data> }) {
  const [activeSort, setActiveSort] = React.useState<ActiveSort<Data> | undefined>(
    options?.defaultSort
  );

  const toggleSort = (column: TableColumn<Data>) => {
    if (activeSort?.column.id === column.id && activeSort.direction === "desc") {
      setActiveSort(undefined);
      return;
    }

    setActiveSort({
      column,
      direction:
        activeSort?.column.id === column.id && activeSort.direction === "asc" ? "desc" : "asc",
    });
  };

  const sortData = React.useCallback(
    (data: Data[]) => {
      if (activeSort === undefined) {
        return data;
      }

      const { column, direction } = activeSort;

      return [...data].sort((a, b) => {
        if (column.sortFn !== undefined) {
          return direction === "asc"
            ? column.sortFn(getByPath(a, column.id), getByPath(b, column.id))
            : column.sortFn(getByPath(b, column.id), getByPath(a, column.id));
        }

        const oneOfValues = getByPath(a, column.id) ?? getByPath(b, column.id);
        const isDateValue =
          oneOfValues instanceof LocalDate ||
          oneOfValues instanceof LocalDateTime ||
          oneOfValues instanceof Instant;

        if (column.transform !== undefined && !isDateValue) {
          return direction === "asc"
            ? defaultSortStrategy(
                column.transform(getByPath(a, column.id), a),
                column.transform(getByPath(b, column.id), a)
              )
            : defaultSortStrategy(
                column.transform(getByPath(b, column.id), b),
                column.transform(getByPath(a, column.id), b)
              );
        }

        return direction === "asc"
          ? defaultSortStrategy(getByPath(a, column.id), getByPath(b, column.id))
          : defaultSortStrategy(getByPath(b, column.id), getByPath(a, column.id));
      });
    },
    [activeSort]
  );

  return { activeSort, toggleSort, sortData };
}

const defaultPerPage = 25;
const perPageOptions = [10, 25, 50, 100];

function usePagination<Data>(options: { data: Data[]; perPage?: number; defaultPage?: number }) {
  const [currentPage, setCurrentPage] = React.useState(options?.defaultPage ?? 1);
  const [perPage, setPerPage] = React.useState(options?.perPage ?? defaultPerPage);

  const pageData = React.useMemo(
    () => options.data.slice((currentPage - 1) * perPage, currentPage * perPage),
    [options.data, currentPage, perPage]
  );

  const totalPages = React.useMemo(
    () => Math.ceil(options.data.length / perPage),
    [options.data, perPage]
  );

  const hasNextPage = currentPage < totalPages;
  const hasPreviousPage = currentPage > 1;

  return {
    pageData,
    totalPages,
    currentPage,
    setCurrentPage,
    hasNextPage,
    hasPreviousPage,
    perPage,
    setPerPage,
  };
}

function useColumnVisibility<Data>(options: { columns: TableColumn<Data>[] }) {
  const [visibleColumns, setVisibleColumns] = React.useState<
    TableColumn<Data, Path<Data>>[] | null
  >(null);

  function setVisibleColumnsFromMenu(columns: string[] | string) {
    if (typeof columns === "string") {
      columns = [columns];
    }

    setVisibleColumns(options.columns.filter((column) => columns.includes(column.id as string)));
  }

  return {
    visibleColumns: visibleColumns ?? options.columns,
    setVisibleColumns,
    setVisibleColumnsFromMenu,
  };
}

export default function DataTable<Data>(props: DataTableProps<Data>) {
  const { activeSort, toggleSort, sortData } = useSortDataTable<Data>({
    defaultSort: (() => {
      if (props.defaultOptions?.sort === undefined) {
        return undefined;
      }

      const column = props.columns.find((col) => col.id === props.defaultOptions?.sort?.column);
      invariant(column !== undefined, "default sort column not found in columns");

      return {
        column,
        direction: props.defaultOptions.sort.direction,
      };
    })(),
  });

  const sortedData = React.useMemo(() => sortData(props.data), [props.data, sortData]);

  const {
    pageData,
    setCurrentPage,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    totalPages,
    perPage,
    setPerPage,
  } = usePagination({
    data: sortedData,
  });

  const { visibleColumns, setVisibleColumnsFromMenu } = useColumnVisibility<Data>({
    columns: props.columns,
  });

  return (
    <Box>
      <Flex justify="space-between" py={4}>
        <Flex wrap="wrap" gap={3}>
          {props.filters}
        </Flex>
        <Flex>
          <Menu closeOnSelect={false}>
            <MenuButton
              aria-label="configure table headers"
              as={IconButton}
              icon={<FaEye fontSize="xl" />}
            />
            <MenuList>
              <MenuOptionGroup
                type="checkbox"
                value={visibleColumns.map((col) => String(col.id))}
                onChange={setVisibleColumnsFromMenu}
              >
                {props.columns.map((column) => (
                  <MenuItemOption key={String(column.id)} value={String(column.id)}>
                    {getLabel(column)}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Box
        maxH="60vh"
        overflow="auto"
        whiteSpace="nowrap"
        sx={{
          "&::-webkit-scrollbar": {
            width: "3px",
            height: "3px",
            backgroundColor: "transparent",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray.300",
            borderRadius: "full",
          },
        }}
      >
        <Table position="relative" zIndex={0}>
          <Thead zIndex={ZIndex.DataTableHead}>
            <Tr>
              {visibleColumns.map((column) => (
                <Th
                  key={String(column.id)}
                  bg="white"
                  p={0}
                  position="sticky"
                  sx={
                    column.sticky === undefined
                      ? undefined
                      : shadowBefore({
                          dropShadow: true,
                          position: column.sticky,
                        })
                  }
                  top={0}
                  zIndex={
                    column.sticky !== undefined ? ZIndex.DataTableStickyTh : ZIndex.DataTableTh
                  }
                >
                  {isColumnSortable(column) ? (
                    <Button
                      as={isColumnSortable(column) ? "button" : "div"}
                      borderTopLeftRadius="md"
                      borderTopRightRadius="md"
                      borderBottomLeftRadius={0}
                      borderBottomRightRadius={0}
                      isActive={activeSort?.column.id === column.id}
                      justifyContent="space-between"
                      p={3}
                      rightIcon={
                        <ArrowDownIcon
                          opacity={activeSort?.column.id === column.id ? 1 : 0}
                          transform={
                            activeSort?.direction == "asc" ? "rotate(0deg)" : "rotate(180deg)"
                          }
                          transition="transform 0.2s"
                        />
                      }
                      fontSize="xs"
                      color="gray.600"
                      variant="ghost"
                      fontWeight="bold"
                      letterSpacing="wider"
                      w="full"
                      onClick={() => toggleSort(column)}
                    >
                      {getLabel(column).toUpperCase()}
                    </Button>
                  ) : (
                    <Text fontSize="sm" fontWeight="semibold" p={4} textTransform="initial">
                      {getLabel(column).toUpperCase()}
                    </Text>
                  )}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {pageData.map((row, i) => (
              <Tr key={i} {...props.trProps?.(row)}>
                {visibleColumns.map((column) => (
                  <Td
                    key={String(column.id)}
                    bg={column.sticky !== undefined ? "white" : undefined}
                    position="sticky"
                    sx={
                      column.sticky === undefined
                        ? undefined
                        : shadowBefore({
                            dropShadow: true,
                            position: column.sticky,
                          })
                    }
                    {...props.tdProps?.({ id: column.id, row })}
                    px={3}
                    onClick={(e) =>
                      props.onClickRow?.(row, { e, isNewTab: e.ctrlKey || e.metaKey })
                    }
                    zIndex={column.sticky !== undefined ? ZIndex.DataTableTd : undefined}
                  >
                    <>{getValue(row, column)}</>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Flex justify="space-between" pt={4}>
        <Flex align="center" gap={4}>
          <ButtonGroup>
            <Button
              isDisabled={!hasPreviousPage}
              variant="outline"
              onClick={() => setCurrentPage((page) => page - 1)}
            >
              Back
            </Button>

            <Button
              isDisabled={!hasNextPage}
              variant="outline"
              onClick={() => setCurrentPage((page) => page + 1)}
            >
              Next
            </Button>
          </ButtonGroup>

          <Text color="gray.500">
            Showing {pageData.length} of {props.data.length} results (page {currentPage} of{" "}
            {totalPages})
          </Text>
        </Flex>

        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="outline">
            {perPage} per page
          </MenuButton>
          <MenuList maxW="fit-content">
            {perPageOptions.map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  setCurrentPage(1);
                  setPerPage(option);
                }}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
}

const shadowBefore = (p: {
  dropShadow: boolean;
  position: "left" | "right";
}): SystemStyleObject => ({
  right: p.position === "right" ? 0 : undefined,
  left: p.position === "left" ? 0 : undefined,
  _before: {
    content: '""',
    position: "absolute",
    top: 0,
    left: p.position === "right" ? 0 : undefined,
    right: p.position === "left" ? 0 : undefined,
    bottom: 0,
    width: 1,
    opacity: p.dropShadow ? 1 : 0,
    transition: "opacity 0.2s",
    backgroundImage:
      p.position === "right"
        ? "linear-gradient(to right, transparent, rgba(0, 0, 0, 0.05))"
        : "linear-gradient(to left, transparent, rgba(0, 0, 0, 0.05))",
  },
});

function getLabel<Data, Property extends Path<Data>>(column: TableColumn<Data, Property>) {
  return column.label ?? camelToHumanCase(String(column.id));
}

function getValue<Data, Property extends Path<Data>>(
  row: Data,
  column: TableColumn<Data, Property>
): React.ReactNode {
  if (column.transform) {
    return column.transform(getByPath(row, column.id), row);
  }

  const value = getByPath(row, column.id);

  if (value instanceof LocalDateTime) {
    return dateFormatter.toDateTime(value);
  }

  if (value instanceof Instant) {
    return dateFormatter.toDateTime(value);
  }

  if (value instanceof LocalDate) {
    return dateFormatter.toDate(value);
  }

  if (Array.isArray(value)) {
    return value.map((v) => getValue({ value: v }, { id: "value" })).join(", ");
  }

  if (value === null || value === undefined) {
    return <>{column.placeholder}</>;
  }

  if (typeof value === "boolean") {
    return value === true ? <Tag colorScheme="green">Yes</Tag> : <Tag colorScheme="red">No</Tag>;
  }

  return <>{value}</>;
}

function isColumnSortable<Data, Property extends Path<Data>>(
  column: TableColumn<Data, Property>
): column is TableColumn<Data, Property> {
  return column.sortable ?? true;
}

function camelToHumanCase(str: string) {
  return str
    .replace(/\./g, " ")
    .replace(/([A-Z0-9]{1})/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
}

function defaultSortStrategy<Value>(a: Value, b: Value) {
  if (a === null && b === null) {
    return 0;
  }

  if (a === null) {
    return -1;
  }

  if (b === null) {
    return 1;
  }

  if (typeof a === "string" && typeof b === "string") {
    return a.localeCompare(b);
  }

  if (typeof a === "number" && typeof b === "number") {
    return a - b;
  }

  if (a instanceof LocalDateTime && b instanceof LocalDateTime) {
    return a.compareTo(b);
  }

  if (a instanceof Instant && b instanceof Instant) {
    return a.compareTo(b);
  }

  if (a instanceof LocalDate && b instanceof LocalDate) {
    return a.compareTo(b);
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length - b.length;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length - b.length;
  }

  return 0;
}
