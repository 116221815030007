import { useState } from "react";
import useActiveCall, { ActiveCallAdditionalData } from "./useActiveCall";
import * as callCenterUtils from "../../shared/utils/call-center";
import useSocketEvent from "./useSocketEvent";

const usePhoneNumberDialer = (onSuccessCall?: () => void) => {
  const [isCalling, setIsCaling] = useState(false);
  const { activeCall, setActiveCall } = useActiveCall();
  const [phoneNumberDialed, setPhoneNumberDialed] = useState("");

  useSocketEvent({
    key: "CallCenterOutboundCall",
    onEvent: (event) => {
      console.log("got event from socket CallCenterOutboundCall", event);
      const additionalData: ActiveCallAdditionalData = (() => {
        switch (event.callee.type) {
          case "AgencyMember":
          case "Caregiver":
          case "Patient":
            return {
              ...event.callee,
              source: event.callee.phoneNumber,
            };
          case "NotIdentifiedPhoneNumber":
            return {
              type: "NotIdentifiedPhoneNumber",
              notIdentifiedType: "NotIdentifiedPhoneNumber",
              source: phoneNumberDialed,
            };
        }
      })();

      setActiveCall({
        participantId: event.participantId,
        sessionToken: event.sessionToken,
        status: "Active",
        ticketId: event.ticketId,
        callSessionId: event.callSessionId,
        liveCallModalOpen: true,
        additionalData: additionalData,
        isMuted: false,
      });
    },
  });

  const dialNumber = (E164Number: string) => {
    setIsCaling(true);
    setPhoneNumberDialed(E164Number);
    console.log(`Calling: ${E164Number}`);
    callCenterUtils.startOutboundCall(E164Number, {
      onSessionConnected: () => {
        console.log("session connected");
        setIsCaling(false);
        onSuccessCall?.();
      },
      onSessionTerminated: () => {
        console.log("session terminated");
        setIsCaling(false);
        if (activeCall !== null) {
          setActiveCall({ ...activeCall, status: "Completed" });
        }
      },
    });
  };

  return { dialNumber, isCalling };
};

export default usePhoneNumberDialer;
