import React from "react";
import { Messages } from "../../../../core/api";
import styled from "@emotion/styled";
import { Duration, LocalDateTime } from "@js-joda/core";
import { Text } from "@chakra-ui/react";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { getMedian } from "../../../../shared/utils/math";

interface IntakeStatsHeaderProps {
  patients: Messages["IntakePatientDashboardDetails"][];
}

const formatDuration = (duration: Duration): string => {
  const days = duration.toDays();
  if (days >= 1) {
    return `${Math.floor(days)} d`;
  }

  const hours = duration.toHours();
  if (hours >= 1) {
    return `${Math.floor(hours)} h`;
  }

  const minutes = duration.toMinutes();
  return `${Math.floor(minutes)} min`;
};

const getLeadToCustomerRatioColor = (medianLeadToCustomerRatio: number) => {
  if (medianLeadToCustomerRatio < 0.5) {
    return "red";
  } else if (medianLeadToCustomerRatio < 0.7) {
    return "orange";
  } else {
    return "green";
  }
};

const getFirstResponseTimeColor = (medianFirstResponseTime: Duration | undefined) => {
  if (medianFirstResponseTime === undefined) {
    return "inherit";
  }
  if (medianFirstResponseTime.toMinutes() > 60) {
    return "red";
  }
  if (medianFirstResponseTime.toMinutes() > 30) {
    return "orange";
  }
  return "green";
};

const getTimeToAquirePatientColor = (medianTimeToAquirePatient: Duration | undefined) => {
  if (medianTimeToAquirePatient === undefined) {
    return "inherit";
  }
  if (medianTimeToAquirePatient.toDays() > 50) {
    return "red";
  }
  if (medianTimeToAquirePatient.toDays() > 30) {
    return "orange";
  }
  return "green";
};

const IntakeStatsHeader = (props: IntakeStatsHeaderProps) => {
  const relevantStatsTimeSpan = LocalDateTime.now().minusDays(60);
  const relevantPatients = props.patients.filter((patient) =>
    dateFormatter.toLocalDateTime(patient.createdAt).isAfter(relevantStatsTimeSpan)
  );
  const { medianLeadToCustomerRatio, medianFirstResponseTime, medianTimeToAquirePatient } = (() => {
    let numOfCustomerPatients = 0;
    const firstResponseTime: number[] = [];
    const timeToAquirePatient: number[] = [];

    for (const patient of relevantPatients) {
      const localDateTimeCreatedAt = dateFormatter.toLocalDateTime(patient.createdAt);
      if (patient.status === "ACTIVE") {
        numOfCustomerPatients++;
      }

      if (patient.lastCall !== null) {
        const leadFirstResponseTime = Duration.between(localDateTimeCreatedAt, patient.lastCall);
        firstResponseTime.push(leadFirstResponseTime.seconds());
      }

      if (patient.active_date !== null) {
        const localDateTimeActiveDate = dateFormatter.toLocalDateTime(patient.active_date);
        const leadTimeToAquire = Duration.between(localDateTimeCreatedAt, localDateTimeActiveDate);
        timeToAquirePatient.push(leadTimeToAquire.seconds());
      }
    }

    const medianLeadToCustomerRatio =
      relevantPatients.length === 0 ? 0 : numOfCustomerPatients / relevantPatients.length;
    const medianFirstResponseTime = getMedian(firstResponseTime);
    const medianTimeToAquirePatient = getMedian(timeToAquirePatient);

    return {
      medianLeadToCustomerRatio,
      medianFirstResponseTime:
        medianFirstResponseTime !== undefined
          ? Duration.ofSeconds(Math.floor(medianFirstResponseTime))
          : undefined,
      medianTimeToAquirePatient:
        medianTimeToAquirePatient !== undefined
          ? Duration.ofSeconds(Math.floor(medianTimeToAquirePatient))
          : undefined,
    };
  })();

  const medianLeadToCustomerRatioText =
    Math.round(medianLeadToCustomerRatio * 100).toFixed(0) + "%";
  const medianFirstResponseTimeText =
    medianFirstResponseTime !== undefined ? formatDuration(medianFirstResponseTime) : "N/A";
  const medianTimeToAquirePatientText =
    medianTimeToAquirePatient !== undefined ? formatDuration(medianTimeToAquirePatient) : "N/A";

  return (
    <IntakeStatsHeader.Root>
      <IntakeStatsHeader.Stat>
        <IntakeStatsHeader.PrimaryText
          color={getLeadToCustomerRatioColor(medianLeadToCustomerRatio)}
        >
          {medianLeadToCustomerRatioText}
        </IntakeStatsHeader.PrimaryText>
        <IntakeStatsHeader.SecondaryText>
          {"Lead to customer ratio"}
        </IntakeStatsHeader.SecondaryText>
      </IntakeStatsHeader.Stat>
      <IntakeStatsHeader.Stat>
        <IntakeStatsHeader.PrimaryText color={getFirstResponseTimeColor(medianFirstResponseTime)}>
          {medianFirstResponseTimeText}
        </IntakeStatsHeader.PrimaryText>
        <IntakeStatsHeader.SecondaryText>
          {"Mean first response time"}
        </IntakeStatsHeader.SecondaryText>
      </IntakeStatsHeader.Stat>
      <IntakeStatsHeader.Stat>
        <IntakeStatsHeader.PrimaryText
          color={getTimeToAquirePatientColor(medianTimeToAquirePatient)}
        >
          {medianTimeToAquirePatientText}
        </IntakeStatsHeader.PrimaryText>
        <IntakeStatsHeader.SecondaryText>
          {"Mean time to aquire patient"}
        </IntakeStatsHeader.SecondaryText>
      </IntakeStatsHeader.Stat>
    </IntakeStatsHeader.Root>
  );
};

IntakeStatsHeader.Root = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: 28px;
`;

IntakeStatsHeader.Stat = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  border-width: 2px;
  border-radius: 10px;
  padding: 15px;
  margin-top: 4px;
  min-width: 300px;
  max-width: 300px;
  min-height: 40px;
  max-height: 40px;
`;

IntakeStatsHeader.PrimaryText = styled(Text)`
  font-size: 25px;
`;

IntakeStatsHeader.SecondaryText = styled(Text)`
  color: gray;
`;

export default IntakeStatsHeader;
