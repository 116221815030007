import { LocalDate, nativeJs } from "js-joda";
import moment from "moment";
import _ from "lodash";

//! @ngInject
export function entityNewVisitModalService($rootScope, DatabaseApi, dateUtils) {
        const selectionTypes = {
            singles: "single",
            permanents: "permanent",
            permanentsMixed: "permanent_mixed",
            mixed: "mixed",
        };
        
        const newVisitData = {};
        const editVisitsData = {};
        const deleteVisitsData = {};
        const missedVisitsData = {};
        const stopBroadcastVisitsData = {};
        const selectedItems = { visits: [], vacations: [] };

        const editShiftsOptions = [
            {
                type: "CURRENT_SHIFTS",
                text: "Choose selected shifts",
                tooltipText: "Will not impact weekly template",
                isDisabled: false,
                disabledReason: "",
            },
            {
                type: "CHOSEN_DAYS",
                text: "",
                tooltipText: "Will impact weekly template (except temporary visits)",
                isDisabled: true,
                disabledReason: "This option is available only on permanent visits",
            },
            // {
            //     type: "GOING_FORWARD",
            //     text: "Choose selected shift going forward",
            //     isDisabled: true,
            //     disabledReason: "This option is available when one permanent visit is selected",
            // },
            {
                type: "UNTIL_DATE",
                text: "",
                tooltipText: "Will not impact weekly template",
                isDisabled: true,
                disabledReason: "This option is available only on permanent visits",
            },
            // {
            //     type: "ENTIRE_SERIES",
            //     text: "Choose entire series",
            //     isDisabled: true
            // },
        ];

        const setNewVisitData = ({
            patientId,
            patientContracts,
            patientMainLanguage,
            patientSecondaryLanguage,
            patientPhoneNumbers,
            patientVacations,
            patientAuthorizations,
            calendarDate
        }) => {
            newVisitData.patientId = patientId;
            newVisitData.patientContracts = patientContracts;
            newVisitData.patientMainLanguage = patientMainLanguage;
            newVisitData.patientSecondaryLanguage = patientSecondaryLanguage;
            newVisitData.patientPhoneNumbers = patientPhoneNumbers;
            newVisitData.patientVacations = patientVacations;
            newVisitData.patientAuthorizations = patientAuthorizations;
            newVisitData.calendarDate = calendarDate;
        };

        const setEditVisitData = ({
            patientId,
            patientContracts,
            patientMainLanguage,
            patientSecondaryLanguage,
            patientPhoneNumbers,
            patientVacations,
            patientAuthorizations,
            targetElementId,
            editShiftsParams,
            editShiftsParamsUntilDate,
            visitInstancesDetails,
        }) => {
            editVisitsData.patientId = patientId;
            editVisitsData.patientContracts = patientContracts;
            editVisitsData.patientMainLanguage = patientMainLanguage;
            editVisitsData.patientSecondaryLanguage = patientSecondaryLanguage;
            editVisitsData.patientPhoneNumbers = patientPhoneNumbers;
            editVisitsData.patientVacations = patientVacations;
            editVisitsData.patientAuthorizations = patientAuthorizations;
            editVisitsData.targetElementId = targetElementId;
            editVisitsData.editShiftsParams = editShiftsParams;
            editVisitsData.editShiftsParamsUntilDate = editShiftsParamsUntilDate;
            editVisitsData.visitInstancesDetails = visitInstancesDetails;
        };

        const setDeleteVisitData = ({
            patientId,
            selectedVisits,
            editShiftsParams,
        }) => {
            deleteVisitsData.patientId = patientId;
            deleteVisitsData.selectedVisits = selectedVisits;
            deleteVisitsData.editShiftsParams = editShiftsParams;
        }

        const setMissedVisitData = ({
            patientId,
            selectedVisits,
            editShiftsParams,
        }) => {
            missedVisitsData.patientId = patientId;
            missedVisitsData.selectedVisits = selectedVisits;
            missedVisitsData.editShiftsParams = editShiftsParams;
        }

        const setStopBroadcastVisitData = ({
            editShiftsParams,
        }) => {
            stopBroadcastVisitsData.editShiftsParams = editShiftsParams;
        };

        const setSelectedItems = ({ visits, vacations }) => {
            if (visits) {
                selectedItems.visits = visits.sort((left, right) =>
                    moment.utc(left.startTime).diff(moment.utc(right.startTime))
                );
                editVisitsData.editShiftsOptions = angular.copy(editShiftsOptions);
                setEditModeOptions();
                notifyObservers(observerCallbacks.visits);
                updateSelectionOptionsText(selectedItems.visits);
            }
            if (vacations) {
                selectedItems.vacations = vacations;
                notifyObservers(observerCallbacks.vacations);
            }
        }

        const updateSelectionOptionsText = (visits) => {
            const days = Object.keys(_.groupBy(visits, (visit) => visit.startTime.format('dddd')));
            const daysArr = days.map(day => day + "s");

            editShiftsOptions.find(option => option.type === "CHOSEN_DAYS").text = "Choose all " + daysArr.join(", ") + " going forward";
            editShiftsOptions.find(option => option.type === "UNTIL_DATE").text = "Choose all " + daysArr.join(", ") + " until date";
        };

        const getSelectionType = (visits) => {
            const batchTypes = Object.keys(_.groupBy(visits, 'visitBatchType'));

            if (batchTypes.length > 0) {
                if (batchTypes.length > 1) {
                    return selectionTypes.mixed;
                }

                if (batchTypes[0] === "PERMANENT") {
                    return isOnlyPermanentsFromTheSameBatch(visits)
                        ? selectionTypes.permanents
                        : selectionTypes.permanentsMixed;
                }

                return selectionTypes.singles;
            }

            return null;
        };

        const isOnlyPermanentsFromTheSameBatch = (visits) => {
            if (visits.length !== visits.filter(visit => visit.visitBatchType === 'PERMANENT').length) {
                return false;
            }

            if (Object.keys(_.groupBy(visits, 'visitBatchId')).length === 1) {
                return true;
            }

            return false;
        };

        const setEditModeOptions = () => {
            let enable = [];
            const selectionType = getSelectionType(selectedItems.visits);

            if (selectionType === null) {
                return;
            }

            switch (selectionType) {
                case selectionTypes.singles:
                case selectionTypes.mixed:
                    enable = ["CURRENT_SHIFTS"];
                    break;

                case selectionTypes.permanents:
                case selectionTypes.permanentsMixed:
                        enable = ["CURRENT_SHIFTS", "UNTIL_DATE", "CHOSEN_DAYS"];
                    break;
                default:
                    console.error(`Unhandled selection type: "${selectionType}"`);
            }

            const disable = editShiftsOptions.map(option => option.type).filter(type => !enable.includes(type));

            enable.forEach(type => {
                editShiftsOptions.find(option => option.type === type).isDisabled = false;
            });

            disable.forEach(type => {
                editShiftsOptions.find(option => option.type === type).isDisabled = true;
            });
        };

        const fetchAllVisits = (patientId, editShiftsParams, selectedVisits) => {
            const fetchVisitInstancesUrl = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/visits/bulk"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId)
                .replace(":patientId", patientId);

            let body = {
				type: "SelectedShifts",
				visitInstanceIds: selectedVisits.map(v => v.visitInstanceId),
			};

			selectedVisits = selectedVisits.sort(function (a, b) {
				return a.startTime.toDate() - b.startTime.toDate();
			});

			const dayOfWeeks = _.uniq(selectedVisits
				.map(visit => moment(visit.date, 'MM.DD.YYYY').format('dddd').toUpperCase()));
			const from = LocalDate.from(nativeJs(moment(selectedVisits[0].date, 'MM.DD.YYYY')));

			if (editShiftsParams.type === 'ENTIRE_SERIES') {
				body.type = 'EntireSeries';
			} else if (
				editShiftsParams.type === 'UNTIL_DATE'
				&& editShiftsParams.untilDate
			) {
                if (editShiftsParams.untilDate) {
                    body.type = 'UntilDate';
                    body.from = from;
                    body.to = LocalDate.from(nativeJs(moment(editShiftsParams.untilDate)));
                    body.dayOfWeeks = dayOfWeeks;
                } else {
                    body.type = 'GoingForward';
                    body.from = from;
                    body.dayOfWeeks = dayOfWeeks;
                }
			} else if (editShiftsParams.type === 'GOING_FORWARD') {
				body.type = 'GoingForward';
				body.from = from;
				body.dayOfWeeks = dayOfWeeks;
			}   else if (editShiftsParams.type === 'CHOSEN_DAYS') {
                body.type = 'ChosenDays';
            }

			return DatabaseApi.post(fetchVisitInstancesUrl, body);
        };

        const fetchDefaultPayRate = (serviceCodeId, payrollCodeId, startDate, patientId) => {
            const url = "agencies/:agencyId/agency_members/:agencyMemberId/default_visit_pay_rate"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId);

            const localDate = dateUtils.dateToLocalDate(startDate);
            let body = {
                serviceCodeId,
                payrollCodeId,
                startDate: localDate,
                patientId
			};

            return DatabaseApi.post(url, body);
        }

        const observerCallbacks = {
            visits: {},
            vacations: {},
        };

        const resetObserverCallbacks = (key) => {
            if (key) {
                observerCallbacks[key] = {};
            } else {
                Object.keys(observerCallbacks).forEach(key => {
                    observerCallbacks[key] = {};
                });
            }
        };

        const registerObserverCallback = (key, observer, callback) => {
            observerCallbacks[key][observer] = callback;
        };

        const unregisterObserverCallback = (key, observer) => {
            delete observerCallbacks[key][observer];
        };

        const notifyObservers = (observerCallbacks) => {
            Object.values(observerCallbacks).forEach(callback => callback());
        };

        return {
            newVisitData,
            setNewVisitData,
            editVisitsData,
            setEditVisitData,
            fetchAllVisits,
            deleteVisitsData,
            setDeleteVisitData,
            missedVisitsData,
            setMissedVisitData,
            stopBroadcastVisitsData,
            setStopBroadcastVisitData,
            selectedItems,
            setSelectedItems,
            isOnlyPermanentsFromTheSameBatch,
            getSelectionType,
            registerObserverCallback,
            unregisterObserverCallback,
            resetObserverCallbacks,
            editShiftsOptions,
            selectionTypes,
            fetchDefaultPayRate
        };
    };
