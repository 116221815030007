import { atom, useAtom } from "jotai";
import type { InboundCallSocketPayloadAdditionalData } from "../../../../public/admin/modules/call-center/components/call-center.types";
import webappChannel, { CallerCallee } from "../../core/webapp-channel";
import { CallSessionId, CallSessionParticipantId, CommCenterTicketId } from "../schema/schema";
import { useWebappChannelPayloadEvent } from "./useWebappChannelEvent";

export type ActiveCallAdditionalData =
  | InboundCallSocketPayloadAdditionalData
  | CallerCallee.Caregiver;

export interface ActiveCallData {
  ticketId: CommCenterTicketId;
  participantId: CallSessionParticipantId;
  status: "Ringing" | "Active" | "Completed";
  liveCallModalOpen?: boolean;
  sessionToken: string;
  callSessionId: CallSessionId;
  additionalData: ActiveCallAdditionalData;
  isMuted: boolean;
}

const activeCallAtom = atom<ActiveCallData | null>(null);

const useActiveCall = () => {
  const [activeCall, setActiveCall] = useAtom(activeCallAtom);
  useWebappChannelPayloadEvent({
    eventName: "ACTIVE_CALL_SET",
    runInitial: true,
    onEvent: () => {
      const payload = webappChannel.getPayload("ACTIVE_CALL_SET") ?? null;
      setActiveCall(payload);
    },
  });

  const setWebappActiveCall = (activeCall: ActiveCallData | null) => {
    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: {
          type: "setActiveCall",
          payload: activeCall,
        },
      })
    );
  };

  return { activeCall, setActiveCall: setWebappActiveCall };
};

export default useActiveCall;
