import { LocalDate } from "js-joda";

//! @ngInject
export function patientStatusChangeModalCtrl(
    $scope, 
    $rootScope,
    $uibModalInstance,
    DatabaseApi,
    toaster,
    noteConsts,
    entityNoteService,
    ) {

    getPatientActiveStatusIndication();
    const allowedStatuses = ["ACTIVE", "HOSPITALIZED", "ON_HOLD", "DISCHARGED", "DECEASED", "LOST"];
    const agencyPatientStatuses = DatabaseApi.agencyPatientActiveStatuses();

    $scope.allStatusChangeReasonsList = DatabaseApi.statusChangeReasons();
    $scope.allStatusChangeToList = DatabaseApi.statusChangeTos();
    $scope.statusChangeModel = {
        status: "",
        updateStatusOnPatient: true
    };

    $scope.dateOptions = {
        startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    $scope.note = { 
    };

    const FIELDS = {
        CONTRACT: "CONTRACT",
        EFFECTIVE_DATE: "EFFECTIVE_DATE",
        REASON: "REASON",
        TO: "TO",
        NOTES: "NOTES",
        FUTURE_VISITS_REMOVAL: "FUTURE_VISITS_REMOVAL",
        UPDATE_ON_PATIENT: "UPDATE_ON_PATIENT",
    };

    $scope.effectiveDateCalendar = false;
    $scope.effectiveDateAppx = "";
    $scope.visibleFields = [];
    $scope.requiredFields = [];
    $scope.statusChangeReasonsList = [];
    $scope.statusChangeToList = [];

    function getPatientActiveStatusIndication() {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/active_status_indication"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", $scope.patientId);

        $scope.isLoadingPatientActiveIndication = true;
        DatabaseApi.get(url).then(function (res) {
            $scope.patientWasActive = res.data.patientWasActive;

            if ($scope.patientWasActive) {
                $scope.agencyPatientStatusesOptions = agencyPatientStatuses.filter(status => allowedStatuses.includes(status.text));
            } else {
                setPatientStatusesOptions($scope.patient.status);
            }
            $scope.isLoadingPatientActiveIndication = false;
        }).catch(function (err) {
            toaster.pop('error', "Failed to load patient active status");
        });
    }

    $scope.handleStatusChange = () => {
        if ($scope.statusChangeModel.status) {
            setPatientStatusesOptions($scope.statusChangeModel.status);
        };

        // Filter Reasons & Tos by status
        $scope.statusChangeReasonsList = $scope.allStatusChangeReasonsList.filter(reason => reason.statuses.indexOf($scope.statusChangeModel.status) !== -1)
        $scope.statusChangeToList = $scope.allStatusChangeToList.filter(to => to.statuses.indexOf($scope.statusChangeModel.status) !== -1)

        switch ($scope.statusChangeModel.status) {
            case "ACTIVE":
                $scope.visibleFields = [FIELDS.EFFECTIVE_DATE, FIELDS.CONTRACT, FIELDS.REASON, FIELDS.TO, FIELDS.NOTES];
                $scope.requiredFields = [FIELDS.EFFECTIVE_DATE, FIELDS.CONTRACT, FIELDS.NOTES];
                $scope.effectiveDateAppx = "Start of care";
                break;
            case "DISCHARGED":
                $scope.visibleFields = [FIELDS.EFFECTIVE_DATE, FIELDS.CONTRACT, FIELDS.REASON, FIELDS.TO, FIELDS.NOTES, FIELDS.FUTURE_VISITS_REMOVAL, FIELDS.UPDATE_ON_PATIENT];
                $scope.requiredFields = [FIELDS.EFFECTIVE_DATE, FIELDS.CONTRACT, FIELDS.FUTURE_VISITS_REMOVAL, FIELDS.NOTES];
                $scope.effectiveDateAppx = "Discharge date";
                break;
            case "DECEASED":
                $scope.visibleFields = [FIELDS.EFFECTIVE_DATE, FIELDS.CONTRACT, FIELDS.REASON, FIELDS.TO, FIELDS.NOTES, FIELDS.FUTURE_VISITS_REMOVAL, FIELDS.UPDATE_ON_PATIENT];
                $scope.requiredFields = [FIELDS.EFFECTIVE_DATE, FIELDS.CONTRACT, FIELDS.FUTURE_VISITS_REMOVAL, FIELDS.NOTES];
                $scope.effectiveDateAppx = "Deceased date";
                break;
            case "ON_HOLD":
                $scope.visibleFields = [FIELDS.EFFECTIVE_DATE, FIELDS.CONTRACT, FIELDS.REASON, FIELDS.TO, FIELDS.NOTES, FIELDS.FUTURE_VISITS_REMOVAL, FIELDS.UPDATE_ON_PATIENT];
                $scope.requiredFields = [FIELDS.EFFECTIVE_DATE, FIELDS.CONTRACT, FIELDS.FUTURE_VISITS_REMOVAL, FIELDS.NOTES];
                $scope.effectiveDateAppx = "On hold date";
                break;
            case "HOSPITALIZED":
                $scope.visibleFields = [FIELDS.EFFECTIVE_DATE, FIELDS.CONTRACT, FIELDS.REASON, FIELDS.TO, FIELDS.NOTES, FIELDS.FUTURE_VISITS_REMOVAL];
                $scope.requiredFields = [FIELDS.EFFECTIVE_DATE, FIELDS.FUTURE_VISITS_REMOVAL, FIELDS.NOTES];
                $scope.effectiveDateAppx = "Hospitalize date";
                break;
            case "ACCEPTED":
            case "ELIGIBLE":
            case "PENDING_FILES":
            case "REFERRAL":
            case "LOST":
            default:
                $scope.visibleFields = [FIELDS.EFFECTIVE_DATE, FIELDS.NOTES];
                $scope.requiredFields = [FIELDS.EFFECTIVE_DATE, FIELDS.NOTES];
                $scope.effectiveDateAppx = "";
                if (!$scope.statusChangeModel.effectiveDate) {
                    $scope.statusChangeModel.effectiveDate = new Date();
                }
                break;
        }
    }

    // $scope.dischargeModel = {};
    // if (data) {
    //     $scope.dischargeModel = angular.copy(data);
    //     prepareDischargeModelData();
    // }

    $scope.handleNoteChanges = (updatedNote) => {
        $scope.note = updatedNote;
    };

    $scope.submitForm = function () {

        if ($scope.requiredFields.indexOf('NOTES') !== -1) {
            const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote($scope.note, noteConsts.NoteTypes.PATIENT_STATUS);
            if (!isNoteValid) {
                $scope.noteValidations = { isPredefinedValid, isMessageValid };
                return;
            }
            
            $scope.statusChangeModel.note = entityNoteService.buildEntityNoteRequest(
                $scope.note,
                noteConsts.NoteTypes.PATIENT_STATUS,
                { patientId: Number($scope.patientId) }
            );
        }

        if ($scope.form.$invalid) {
            return;
        }
        const statusChangeRequest = enrichStatusChangeForm();
            const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_statuses/patient_status_change"
                        .replace(":agencyId", $rootScope.agencyId)
                        .replace(":agencyMemberId", $rootScope.agencyMemberId)
                        .replace(":patientId", statusChangeRequest.patientId);
            DatabaseApi.post(url, statusChangeRequest).then(function (res) {
                toaster.pop('success', "Status change saved successfully");
                $scope.closeModal();
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not save status change");
            });
    };

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    function enrichStatusChangeForm() {
        const statusChangeRequest = angular.copy($scope.statusChangeModel);
        statusChangeRequest.patientId = Number($scope.patientId);
        let d = new Date(statusChangeRequest.effectiveDate);
        statusChangeRequest.effectiveDate = LocalDate.of(d.getFullYear(), d.getMonth() + 1, d.getDate()).toString();

        // Regardless of client choosing (is unavailable to choose on those statuses) - force set update on patient to true
        if ($scope.visibleFields.indexOf(FIELDS.UPDATE_ON_PATIENT) === -1) {
            $scope.statusChangeModel.updateStatusOnPatient = true;
        }

        return statusChangeRequest;
    }

    function setPatientStatusesOptions(status) {
        if($scope.patientWasActive) return;
        
        switch (status) {
            case "ACTIVE":
                $scope.agencyPatientStatusesOptions = agencyPatientStatuses.filter(status => allowedStatuses.includes(status.text));
                break;
            default:
                $scope.agencyPatientStatusesOptions = agencyPatientStatuses;
                break;
        }
    }

    // function prepareDischargeModelData() {
    //     var d = LocalDate.parse($scope.dischargeModel.dischargeDate);
    //     $scope.dischargeModel.dischargeDate = new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
    //     if($scope.dischargeModel.reinstateDate) {
    //         d = LocalDate.parse($scope.dischargeModel.reinstateDate);
    //         $scope.dischargeModel.reinstateDate = new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
    //     }
    // }

    // $scope.deleteReinstateField = function() {
    //     $scope.dischargeModel.reinstateDate = null;
    //     $scope.enableReinstate = false;
    // }
};