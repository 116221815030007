import { Flex, FlexProps, Spinner } from "@chakra-ui/react";
import React from "react";
import { match } from "ts-pattern";
import AudioPlayer from "../../../../../../shared/components/AudioPlayer/AudioPlayer";
import BaselinePlayCircleIcon from "../../../../../../shared/icons/BaselinePlayCircleIcon";
import useCommCenterCallRecording from "../../../../hooks/useCommCenterCallRecording";
import CallTicketText from "../CallTicketText";

interface Props extends FlexProps {
  state: ReturnType<typeof useCommCenterCallRecording>;
}

const CallTicketRecording = ({ state, ...props }: Props) => {
  return match(state)
    .with({ type: "NoRecording" }, () => <></>)
    .with({ type: "HasRecording" }, (state) => {
      return (
        <DefaultFlex
          cursor={"pointer"}
          color={"blue.500"}
          onClick={state.onRequestRecording}
          {...props}
        >
          <BaselinePlayCircleIcon />
          <CallTicketText>Play Recording</CallTicketText>
        </DefaultFlex>
      );
    })
    .with({ type: "LoadingRecording" }, () => <Spinner />)
    .with({ type: "RecodringReady" }, ({ recordingUrl }) => {
      return (
        <DefaultFlex {...props}>
          <AudioPlayer src={recordingUrl} ratesOptions={[1, 1.25, 1.5, 2]} />
        </DefaultFlex>
      );
    })
    .with({ type: "ErrorLoading" }, () => {
      return (
        <DefaultFlex color={"red"} cursor={"auto"} {...props}>
          Error loading recording
        </DefaultFlex>
      );
    })
    .exhaustive();
};

const DefaultFlex = (props: FlexProps) => {
  return <Flex gap={"inherit"} alignItems={"inherit"} {...props}></Flex>;
};

export default CallTicketRecording;
