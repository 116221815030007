import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { TicketSummaryDisclosureState } from "../hooks/useTicketSummaryDisclosure";
import TicketSummaryDisclosureContent from "./TicketSummaryDisclosureContent";

const TicketSummaryModal = (ticketSummaryDisclosure: {
  disclosureProps: {
    isOpen: boolean;
    isControlled: boolean;
    onOpen: (state: TicketSummaryDisclosureState) => void;
    onClose: () => void;
    onToggle: () => void;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
  };
  disclosureState: TicketSummaryDisclosureState;
}) => {
  return (
    <Modal size="lg" {...ticketSummaryDisclosure.disclosureProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ticket Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TicketSummaryDisclosureContent {...ticketSummaryDisclosure.disclosureState} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={ticketSummaryDisclosure.disclosureProps.onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TicketSummaryModal;
