import { PhoneIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/react";
import { Duration } from "@js-joda/core";
import React from "react";
import { FaHourglassHalf } from "react-icons/fa";
import { Messages } from "../../../../core/api";
import { fmap } from "../../../../shared/utils";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { durationFormatter } from "../../../../shared/utils/duration-formatter";
import { phoneFormatter } from "../../../../shared/utils/phone-formatter";
import useCommCenterCallRecording from "../../hooks/useCommCenterCallRecording";
import CallTicketEventCard from "./helpers/CallTicketEventCard/CallTicketEventCard";
import CallTicketParticipantCard from "../CallTicketParticipantCard";
import CallTicketColumn from "./helpers/CallTicketColumn";
import CallTicketRow from "./helpers/CallTicketRow";
import PhoneCallStatus from "../PhoneCallStatus";
import CallNotesSection from "./CallNotesSection";
import useCallTicketNotes from "./hooks/useCallTicketNotes";
import CallNoteCard from "./CallNoteCard";
import { formatDirection, getIconBySatisfactionRank } from "../../utils/communication-utils";
import CallTicketText from "./helpers/CallTicketText";
import CallTicketRecording from "./helpers/CallTicketEventCard/CallTicketRecording";
import ClockIcon from "../../../../shared/icons/ClockIcon";

interface Props {
  activeTicket: Messages["CommCenterTicket"] & {
    callSessionDetails: Messages["CallSessionDetails"];
  };
  notesSectionState: ReturnType<typeof useCallTicketNotes>;
}

const CompletedCallTicket = (props: Props) => {
  const recordingState = useCommCenterCallRecording({
    callSessionDetails: props.activeTicket.callSessionDetails,
    ticketId: props.activeTicket.id,
  });
  const { callSessionDetails } = props.activeTicket;

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      backgroundSize="60%"
      borderRadius="0 12px 12px 0"
      height="100%"
      gap={2}
      padding={2}
    >
      <CallTicketColumn>
        <CallTicketRow flex={1}>
          <Flex gap={4}>
            <Flex direction={"column"}>
              <CallTicketRow.Header>Call Details</CallTicketRow.Header>
              <CallTicketRow.Content>
                <CallTicketRow.ContentRow>
                  <PhoneIcon color={"green.400"} />
                  <CallTicketText>{formatDirection(callSessionDetails.direction)}</CallTicketText>
                </CallTicketRow.ContentRow>
                <CallTicketRow.ContentRow>
                  <ClockIcon />
                  <CallTicketText>
                    {fmap(callSessionDetails.createdAt, (v) => dateFormatter.toDateTime(v))}
                  </CallTicketText>
                </CallTicketRow.ContentRow>
                <CallTicketRow.ContentRow>
                  <FaHourglassHalf size={"14px"} />
                  <CallTicketText>
                    {durationFormatter.hours(
                      Duration.ofSeconds(callSessionDetails.durationSeconds)
                    )}
                  </CallTicketText>
                </CallTicketRow.ContentRow>

                <CallTicketRow.ContentRow>
                  <PhoneIcon />
                  <CallTicketText>
                    {phoneFormatter.formatNationalIfValid(callSessionDetails.externalPhoneNumber)}
                  </CallTicketText>
                </CallTicketRow.ContentRow>

                <CallTicketRow.ContentRow>
                  <PhoneCallStatus
                    call={callSessionDetails}
                    recordingState={{ type: "NoRecording" }}
                  />
                </CallTicketRow.ContentRow>
                <CallTicketRow.ContentRow>
                  <CallTicketRecording state={recordingState} />
                </CallTicketRow.ContentRow>
              </CallTicketRow.Content>
            </Flex>
            <Flex direction={"column"}>
              <CallTicketRow.Header>Call Parties</CallTicketRow.Header>
              <CallTicketRow.Content>
                {callSessionDetails.sessionParticipantRoles.map((role, i) => (
                  <CallTicketRow.ContentRow key={`${role.id}`}>
                    <CallTicketParticipantCard role={role} index={i} />
                  </CallTicketRow.ContentRow>
                ))}
              </CallTicketRow.Content>
            </Flex>
          </Flex>
        </CallTicketRow>
        <CallTicketRow flex={1}>
          <CallTicketRow.Header>Call Notes</CallTicketRow.Header>
          <CallTicketRow.Content gap={4}>
            <CallNotesSection {...props.notesSectionState} ticketId={props.activeTicket.id} />
            {callSessionDetails.sessionNotes.map((note) => (
              <CallNoteCard key={note.id} note={note} />
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow flex={1}>
          <CallTicketRow.Header>Call Events</CallTicketRow.Header>
          <CallTicketRow.Content>
            {callSessionDetails.sessionParticipantEvents
              .slice()
              .sort((a, b) => a.id - b.id)
              .map((event) => (
                <CallTicketRow.ContentRow key={`${event.id}`}>
                  <CallTicketEventCard event={event} />
                </CallTicketRow.ContentRow>
              ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
      <CallTicketColumn>
        <CallTicketRow flex={1}>
          <CallTicketRow.Header>Call Summary</CallTicketRow.Header>
          <CallTicketRow.Content>
            <CallTicketText>
              {props.activeTicket.callSessionDetails.sessionMedia?.summary ?? "No Summary"}
            </CallTicketText>
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow flex={1}>
          <Flex direction={"row"} justifyContent={"space-between"}>
            <CallTicketRow.Header>Call Satisfaction</CallTicketRow.Header>
            {props.activeTicket.callSessionDetails.sessionMedia?.satisfactionRankNumber &&
              getIconBySatisfactionRank(
                props.activeTicket.callSessionDetails.sessionMedia?.satisfactionRankNumber
              )}
          </Flex>
          <CallTicketRow.Content>
            <CallTicketText>
              {props.activeTicket.callSessionDetails.sessionMedia?.satisfactionRankDescription ??
                "No Description"}
            </CallTicketText>
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow flex={1}>
          <CallTicketRow.Header>Call Transcription</CallTicketRow.Header>
          <CallTicketRow.Content>
            <CallTicketText>
              {props.activeTicket.callSessionDetails.sessionMedia?.transcription ??
                "No Transcription"}
            </CallTicketText>
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
    </Flex>
  );
};

export default CompletedCallTicket;
