import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React from "react";
import { BodyOf, Messages, QueryParamsOf, ResponseOf } from "../../../../core/api";
import RangeDatePicker from "../../../../shared/components/DatePicker/RangeDatePicker";
import FreeTextSearch from "../../../../shared/components/FreeTextSearch";
import MultiSelectMenu from "../../../../shared/components/MultiSelectMenu";
import Page from "../../../../shared/components/Page";
import { createFilters } from "../../../../shared/hooks/useFilters";
import AddRoundedIcon from "../../../../shared/icons/AddRoundedIcon";
import { PatientId } from "../../../../shared/schema/schema";
import { FilterProps } from "../../../../shared/utils/filter-props";
import IntakeDashboardCardTable from "../../components/dashboard/IntakeDashboardCardTable";
import IntakeNextCallDateFilterCards from "../../components/dashboard/IntakeNextCallDateFilterCards";
import IntakeStatsHeader from "../../components/dashboard/IntakeStatsHeader";
import NewIntakePatient from "../../components/NewIntakePatient";

type IntakeDashboardQueryParams = QueryParamsOf<"get", "./patient_intake/dashboard">;

interface Props extends FilterProps<"./patient_intake/dashboard"> {
  createIntakePatientDisclosure: UseDisclosureReturn;
  intakePatients: Messages["IntakePatientDashboardDetails"][];
  medicaidStatuses: Messages["MedicaidStatus"][];
  intakeStatuses: ResponseOf<"get", "./patient_intake/intake_status">["intakeStatuses"];
  intakePlans: Messages["IntakePlan"][];
  onClickDashboardRow: (patientId: PatientId) => void;
  onCreateNewIntakePatient: (val: BodyOf<"post", "./patient_intake">) => void;
}

const IntakeDashboardPage = (props: Props) => {
  const { createMultiSelectFilter, createRangeDatePickerFilter, createFreeTextFilter } =
    createFilters<IntakeDashboardQueryParams>();

  const fromToFilter = createRangeDatePickerFilter({
    label: "Filter By Last Call Range",
    startDate: {
      name: "lastCallFrom",
      value: props.filters.getValueOrNull("lastCallFrom"),
    },
    endDate: {
      name: "lastCallTo",
      value: props.filters.getValueOrNull("lastCallTo"),
    },
    onChange: props.onChangeFilter,
  });

  const medicaidStatusFilter = createMultiSelectFilter({
    name: "medicaidStatus",
    label: "Medicaid Status",
    onChange: props.onChangeFilter,
    options: props.medicaidStatuses.map((x) => ({
      label: x.name,
      value: x.id,
    })),
    value: props.filters.getValueOrNull("medicaidStatus"),
  });

  const intakeStatusFilter = createMultiSelectFilter({
    name: "intakeStatus",
    label: "Intake Status",
    onChange: props.onChangeFilter,
    options: props.intakeStatuses.map((status) => ({
      label: status,
      value: status,
    })),
    value: props.filters.getValueOrNull("intakeStatus"),
  });

  const intakePlansFilter = createMultiSelectFilter({
    name: "plan",
    label: "Plan",
    onChange: props.onChangeFilter,
    options: props.intakePlans.map((plan) => ({
      label: plan.name,
      value: plan.id,
    })),
    value: props.filters.getValueOrNull("plan"),
  });

  const freeTextSearchFilter = createFreeTextFilter({
    placeholder: "Search by patient name, id, etc.",
    name: "freeTextSearch",
    value: props.filters.getValueOrNull("freeTextSearch"),
    onChange: props.onChangeFilter,
    debounce: 250,
  });

  const nextCallDateFilter = createRangeDatePickerFilter({
    label: "Filter By Next Call Range",
    startDate: {
      name: "nextCallFrom",
      value: props.filters.getValueOrNull("nextCallFrom"),
    },
    endDate: {
      name: "nextCallTo",
      value: props.filters.getValueOrNull("nextCallTo"),
    },
    onChange: props.onChangeFilter,
  });

  const handleClickDashboardRow = (patientId: PatientId) => {
    props.onClickDashboardRow(patientId);
  };

  return (
    <Page>
      <Page.Header display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        {/* <Heading size={"lg"}>Intake Dashboard</Heading> */}
        <Heading size={"lg"} display="inline-block">
          Intake Dashboard
        </Heading>
        <IntakeStatsHeader patients={props.intakePatients} />
      </Page.Header>

      <IntakeNextCallDateFilterCards {...nextCallDateFilter} patients={props.intakePatients} />

      <Page.Filters>
        <Flex direction="column" gap={5}>
          {/* <Heading size={"sm"}>Filters</Heading> */}
          <Flex gap={3}>
            <FreeTextSearch
              {...freeTextSearchFilter}
              placeholder={"Search by patient name, id, etc."}
            />
            <RangeDatePicker {...fromToFilter} placeholderText={"Last call date"} />
            <MultiSelectMenu {...medicaidStatusFilter} />
            <MultiSelectMenu {...intakeStatusFilter} />
            <MultiSelectMenu {...intakePlansFilter} />
            <Button
              left={"0px"}
              ms="auto"
              colorScheme="blue"
              leftIcon={<AddRoundedIcon />}
              onClick={props.createIntakePatientDisclosure.onOpen}
            >
              New Intake patient
            </Button>
          </Flex>
        </Flex>
      </Page.Filters>
      <Divider />
      <Page.Content>
        <IntakeDashboardCardTable
          patients={props.intakePatients}
          onClickPatientCard={handleClickDashboardRow}
        />
        {/* <IntakeDashboardTable
          patients={props.intakePatients}
          onClickDashboardRow={handleClickDashboardRow}
        /> */}

        {/** Start New Intake Patient Modal */}
        <Drawer {...props.createIntakePatientDisclosure} size="xl">
          <DrawerContent>
            <DrawerCloseButton size={"lg"} mr={2} />
            <DrawerHeader>Add New Intake Patient</DrawerHeader>
            <DrawerBody>
              <NewIntakePatient onCreateNewIntakePatient={props.onCreateNewIntakePatient} />
            </DrawerBody>
          </DrawerContent>
          <DrawerOverlay />
        </Drawer>
        {/** End New Intake Patient Modal */}
      </Page.Content>
    </Page>
  );
};

export default IntakeDashboardPage;
