import { LocalDateTime } from "@js-joda/core";
import React from "react";
import BaseDatePicker, { BaseDatePickerProps } from "./BaseDatePicker";
import { Button, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";

const Calendar = styled.div`
  display: flex;
  width: fit-content;
  gap: 12px;
`;

const Actions = styled.div`
  border-top: 1px solid var(--chakra-colors-gray-200);
  padding-top: 8px;
  margin-top: 8px;
`;

export interface SingleDateTimePickerProps
  extends Omit<BaseDatePickerProps, "monthsShown" | "onChange"> {
  onChange: (
    date: LocalDateTime | null,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => void;
}

const SingleDateTimePicker = (props: SingleDateTimePickerProps) => {
  const handleSetClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return props.onChange(null, e);
  };

  const handleSetToday = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return props.onChange(LocalDateTime.now(), e);
  };

  return (
    <BaseDatePicker
      {...props}
      showTimeSelect
      shouldCloseOnSelect={false}
      timeFormat="HH:mm"
      dateFormat="MM/dd/yyyy HH:mm"
      timeIntervals={15}
      monthsShown={1}
      calendarContainer={({ children }) => {
        return (
          <>
            <Calendar>{children}</Calendar>
            <Actions>
              <Flex alignItems="center" justifyContent="space-between" gap={8}>
                <Button variant="ghost" size="sm" color="negative" onClick={handleSetClear}>
                  Clear
                </Button>
                <Button variant="ghost" size="sm" onClick={handleSetToday}>
                  Today
                </Button>
              </Flex>
            </Actions>
          </>
        );
      }}
    />
  );
};

export default SingleDateTimePicker;
