import { Divider, Flex, Heading, Tag, Text } from "@chakra-ui/react";
import { useRouter } from "@uirouter/react";
import React from "react";
import { Messages, QueryParamsOf, ResponseOf } from "../../../../core/api";
import MultiSelectMenu from "../../../../shared/components/MultiSelectMenu";
import Page from "../../../../shared/components/Page";
import { createFilters } from "../../../../shared/hooks/useFilters";
import { FilterProps } from "../../../../shared/utils/filter-props";
import { getFullName } from "../../../../shared/utils/get-full-name";
import DataTable from "../../components/DataTable";

interface Props extends FilterProps<"./workflow_tasks/tasks"> {
  isRefetching: boolean;
  tasks: Messages["ListedWorkflowHumanTaskInstance"][];
  skills: ResponseOf<"get", "./workflow_tasks/skills">["skills"];
  agencyMembers: Messages["AgencyMember"][];
}

const { createMultiSelectFilter } = createFilters<QueryParamsOf<"get", "./workflow_tasks/tasks">>();

export default function WorkflowTasksPage(props: Props) {
  const { stateService } = useRouter();

  const skillsFilter = createMultiSelectFilter({
    name: "assignee",
    label: "Assignee",
    options: props.agencyMembers.map((x) => ({ value: x.id, label: getFullName(x) })),
    value: props.filters.getValueOrNull("assignee"),
    onChange: props.onChangeFilter,
  });

  const filters = <MultiSelectMenu {...skillsFilter} />;

  return (
    <Page isLoading={props.isRefetching}>
      <Page.Header>
        <Flex gap={8} wrap="wrap" justifyContent="space-between">
          <Heading size="lg">Tasks ({props.tasks.length})</Heading>
        </Flex>
      </Page.Header>

      <Divider />

      <Page.Content>
        <DataTable
          data={props.tasks}
          filters={filters}
          columns={[
            { id: "id" },
            {
              id: "meta.name",
              label: "Name",
              transform: (name, { meta }) => {
                return (
                  <Flex direction="column" gap={1}>
                    <Text fontWeight="semibold">{name}</Text>
                    <Text fontSize="sm" color="gray">
                      {meta.description}
                    </Text>
                  </Flex>
                );
              },
            },
            { id: "meta.priority", label: "Priority" },
            {
              id: "meta.severity",
              label: "Severity",
              transform: (s) => <Tag>{s}</Tag>,
            },
            // { id: "meta.timeout", label: "Timeout", placeholder: "None" },
            { id: "meta.mandatory", label: "Mandatory" },
            { id: "skills", transform: (skills) => <Skills skills={skills} /> },
            { id: "workflowDefinition.name", label: "Workflow" },
          ]}
          trProps={() => ({
            cursor: "pointer",
            _hover: {
              bg: "gray.50",
            },
          })}
          onClickRow={(row, { isNewTab }) => {
            isNewTab
              ? window.open(`/app/workflow-tasks/${row.id}`)
              : stateService.go("app.workflow_tasks.task", { id: row.id });
          }}
        />
      </Page.Content>
    </Page>
  );
}

function Skills(props: { skills: string[] }) {
  return (
    <Flex gap={2}>
      {props.skills.map((s) => (
        <Tag key={s}>{s}</Tag>
      ))}
    </Flex>
  );
}
