import {
  Box,
  Button,
  Flex,
  Heading,
  keyframes,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { match } from "ts-pattern";
import { Messages } from "../../../core/api";
import { CachedSignedFileWithShimmer } from "../../../shared/components/CachedSignedFile";
import CachedSignedImageModal from "../../../shared/components/CachedSignedImageModal";
import { CachedSignedVideoWithShimmer } from "../../../shared/components/CachedSignedVideo";
import DocumentChatMessage from "../../../shared/components/DocumentChatMessage";
import CheckDoubleFillIcon from "../../../shared/icons/CheckDoubleFillIcon";
import CheckLineIcon from "../../../shared/icons/CheckLineIcon";
import ClockIcon from "../../../shared/icons/ClockIcon";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { getCommCenterMessageCreatorName } from "../utils/communication-utils";

interface Props {
  message: Messages["CommCenterMessage"];
  displayEntityName: boolean;
}

const ChatMessage = (props: Props) => {
  const sentOrReceived = props.message.createdBy.type === "Caregiver" ? "received" : "sent";
  const entityName = getCommCenterMessageCreatorName(props.message);
  const [firstMessage, ...otherMessages] = props.message.payload;

  const relevantIcon = (() => {
    if (props.message.id < 0) {
      return (
        <Tooltip label="Sending...">
          <ClockIcon color="gray.500" />
        </Tooltip>
      );
    }

    if (props.message.readAt !== null) {
      return (
        <Tooltip label="Read">
          <CheckDoubleFillIcon color="blue.500" />
        </Tooltip>
      );
    }

    return (
      <Tooltip label="Sent">
        <CheckLineIcon color="gray.500" />
      </Tooltip>
    );
  })();

  return (
    <ChatMessage.Root data-is-new={props.message.id < 0} type={sentOrReceived}>
      <ChatMessage.Bubble type={sentOrReceived}>
        <Flex alignItems="center" gap={1} mb={1} justifyContent="space-between">
          {props.displayEntityName && <Heading size="xs">{entityName}</Heading>}
          <Text fontSize="xs" color="gray.700">
            {dateFormatter.toDateOrDateTime(props.message.createdAt)}
          </Text>
          {sentOrReceived === "sent" && <Box px={1}>{relevantIcon}</Box>}
        </Flex>
        <ChatMessage.Content message={firstMessage} />
        {otherMessages.map((payload, i) => (
          <ChatMessage.ContentBubble key={i} type={sentOrReceived}>
            <ChatMessage.Content message={payload} />
          </ChatMessage.ContentBubble>
        ))}
      </ChatMessage.Bubble>
    </ChatMessage.Root>
  );
};

ChatMessage.Content = (props: { message: Messages["CommCenterMessage"]["payload"][number] }) => {
  return match(props.message)
    .with({ type: "TEXT" }, ({ message }) => <Text whiteSpace="break-spaces">{message}</Text>)
    .with({ type: "IMAGE" }, ({ url }) => (
      <CachedSignedImageModal src={url} width={200} height={200} />
    ))
    .with({ type: "DOCUMENT" }, ({ url }) => (
      <DocumentChatMessage
        data={url}
        width={200}
        height={200}
        element={CachedSignedFileWithShimmer}
      />
    ))
    .with({ type: "VIDEO" }, ({ url }) => (
      <CachedSignedVideoWithShimmer width={200} height={200} src={url} />
    ))
    .with({ type: "ACTION" }, ({ payload }) => {
      return match(payload)
        .with({ payloadType: "TEXT" }, (message) => (
          <Text whiteSpace="break-spaces">{message.text}</Text>
        ))
        .with({ payloadType: "IMAGE" }, (image) => (
          <CachedSignedImageModal src={image.url} width={200} height={200} />
        ))
        .with({ payloadType: "DOCUMENT" }, (document) => (
          <DocumentChatMessage
            data={document.url}
            width={200}
            height={200}
            element={CachedSignedFileWithShimmer}
          />
        ))
        .with({ payloadType: "VIDEO" }, (video) => (
          <CachedSignedVideoWithShimmer width={200} height={200} src={video.url} />
        ))
        .with({ payloadType: "LIST" }, (list) => <ChatMessage.ActionsPopover list={list} />)
        .exhaustive();
    })
    .exhaustive();
};

ChatMessage.ActionsPopover = (props: { list: Messages["CommCenterMessagePayloadActionList"] }) => {
  return (
    <Popover placement="auto-end">
      <PopoverTrigger>
        <Button size="sm" colorScheme="blue" variant="outline" w="full">
          Show options
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <List spacing={5}>
            {props.list.items.map((item) => (
              <ListItem key={item.id}>{item.text}</ListItem>
            ))}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

ChatMessage.Bubble = styled(Box)<{ type: "sent" | "received" }>`
  padding: 12px;
  border-radius: 12px;
  min-width: 120px;
  transition: all 250ms ease;

  ${({ type }) => {
    if (type === "received") {
      return css`
        background-color: var(--chakra-colors-gray-100);
      `;
    }

    return css`
      background-color: var(--chakra-colors-blue-100);
    `;
  }}
`;

const chatMessageIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.95);
    }
`;

ChatMessage.Root = styled(Flex)<{ type: "sent" | "received" }>`
  max-width: 80%;
  flex-direction: column;
  gap: 4px;

  &[data-is-new="true"] {
    animation: ${chatMessageIn} 0.3s ease-in-out backwards;
    opacity: 0.75;
  }

  ${({ type }) => {
    if (type === "received") {
      return css`
        margin-inline-end: auto;
        align-items: flex-start;
      `;
    }

    return css`
      margin-inline-start: auto;
      align-items: flex-end;
    `;
  }}
`;

ChatMessage.ContentBubble = styled(ChatMessage.Bubble)`
  border: 1px solid var(--chakra-colors-gray-300);
  margin: 5px;
`;

export default ChatMessage;
