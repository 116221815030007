import { Heading, Stack } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { groupBy, orderBy } from "lodash";
import React from "react";
import { Messages } from "../../../../core/api";
import { PatientId } from "../../../../shared/schema/schema";
import IntakeDashboardCard from "./IntakeDashboardCard";

interface IntakeDashboardCardTableProps {
  patients: Messages["IntakePatientDashboardDetails"][];
  onClickPatientCard: (patientId: PatientId) => void;
}

const IntakeDashboardCardTable = (props: IntakeDashboardCardTableProps) => {
  const oderedPatients = orderBy(props.patients, ["intakeStatus", "lastUpdateDate"]);
  const groupedPatients = groupBy(oderedPatients, "intakeStatus");
  const patients = groupedPatients;

  return (
    <Stack spacing={1}>
      <IntakeDashboardCardTable.Root>
        {Object.keys(patients).map((intakeStatus) => {
          return (
            <IntakeDashboardCardTable.Column>
              <Heading size="md">{`${intakeStatus} (${patients[intakeStatus].length})`}</Heading>
              {patients[intakeStatus].map((patient) => (
                <IntakeDashboardCard
                  patient={patient}
                  onClickPatientCard={props.onClickPatientCard}
                />
              ))}
            </IntakeDashboardCardTable.Column>
          );
        })}
      </IntakeDashboardCardTable.Root>
    </Stack>
  );
};

IntakeDashboardCardTable.Root = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
`;

IntakeDashboardCardTable.Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-around;
  align-items: center;
`;

export default IntakeDashboardCardTable;
