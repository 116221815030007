import { Badge, Center } from "@chakra-ui/react";
import React from "react";
import { ReactNode } from "react";

const CallTicketIndexBadge = ({ children }: { children?: ReactNode }) => {
  return (
    <Badge
      borderWidth={1}
      borderColor={"black"}
      fontWeight={"medium"}
      display={"flex"}
      as={"div"}
      alignItems="center"
      justifyContent={"center"}
      boxSize={{ "2xl": 10, base: 9 }}
      fontSize={{ "2xl": "lg", base: "sm" }}
      rounded={"full"}
    >
      <Center>{children}</Center>
    </Badge>
  );
};

export default CallTicketIndexBadge;
