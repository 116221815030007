import React from "react";
import { createIcon } from "@chakra-ui/react";

const DotsVerticalIcon = createIcon({
  displayName: "DotsVerticalIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="currentColor">
      <rect width="4" height="4" x="10" y="3" rx="2" />
      <rect width="4" height="4" x="10" y="10" rx="2" />
      <rect width="4" height="4" x="10" y="17" rx="2" />
    </g>
  ),
});

export default DotsVerticalIcon;
