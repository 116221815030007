import {
  Box,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
} from "@chakra-ui/react";
import React from "react";
import { BodyOf, Messages, ResponseOf } from "../../../core/api";
import { NoteId, PatientId } from "../../../shared/schema/schema";
import { PatientIntakeProfileUpdate } from "../patient-intake.types";
import IntakePatientProfileBody from "./IntakePatientProfileBody";
import IntakeProfileHeader from "./IntakeProfileHeader";
import { IntakePlan } from "./IntakeProfilePlanInfo";

interface Props {
  intakeProfile: Messages["IntakePatientProfile"];
  intakePlans: IntakePlan[];
  intakeStatuses: ResponseOf<"get", "./patient_intake/intake_status">["intakeStatuses"];
  eligibilityCheckResults: ResponseOf<"get", "./eligibility_checks">["records"];
  communicationLog: ResponseOf<"get", "./comm_center/tickets/patients/:patientId">["tickets"];
  onClickAddNewNote: (patientId: PatientId) => void;
  onClickEligibilityCheck: () => void;
  onEditPatientIntake: (body: BodyOf<"put", "./patients/:patientId/intake">) => void;
  onMarkNoteAsDone: (noteId: NoteId) => void;
  onCloseIntakeProfile: () => void;
}

const IntakePatientProfileDrawer = (props: Props) => {
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [patientIntakeProfile, setPatientIntakeProfile] = React.useState<
    Messages["IntakePatientProfile"]
  >(props.intakeProfile);

  const handleEditModeChange = (newValue: boolean) => {
    setIsEditMode(newValue);
    if (!newValue) {
      props.onEditPatientIntake(patientIntakeProfile);
    }
  };

  function handleEditPatientIntakeProfile<
    $Field extends keyof PatientIntakeProfileUpdate,
    $Value extends PatientIntakeProfileUpdate[$Field]
  >(field: $Field, value: $Value): void;

  function handleEditPatientIntakeProfile(object: PatientIntakeProfileUpdate): void;

  function handleEditPatientIntakeProfile(
    fieldOrObject: keyof PatientIntakeProfileUpdate | PatientIntakeProfileUpdate,
    value?: PatientIntakeProfileUpdate[keyof PatientIntakeProfileUpdate]
  ): void {
    if (typeof fieldOrObject === "object") {
      setPatientIntakeProfile({
        ...patientIntakeProfile,
        ...fieldOrObject,
      });
    } else {
      setPatientIntakeProfile({
        ...patientIntakeProfile,
        [fieldOrObject]: value,
      });
    }
  }

  return (
    <DrawerContent>
      <DrawerHeader>
        <Box>
          <IntakeProfileHeader
            patientInfo={patientIntakeProfile}
            isEditMode={isEditMode}
            onClickEdit={handleEditModeChange}
            onCloseIntakeProfile={props.onCloseIntakeProfile}
          />
        </Box>
        <Divider variant="solid" marginTop={"5px"}></Divider>
      </DrawerHeader>
      <DrawerBody>
        <IntakePatientProfileBody
          patientIntakeProfile={patientIntakeProfile}
          onEditPatientIntakeProfile={handleEditPatientIntakeProfile}
          intakePlans={props.intakePlans}
          intakeStatuses={props.intakeStatuses}
          eligibilityCheckResults={props.eligibilityCheckResults}
          communicationLog={props.communicationLog}
          isEditMode={isEditMode}
          onClickAddNewNote={props.onClickAddNewNote}
          onClickMarkNoteAsDone={props.onMarkNoteAsDone}
          onClickEligibilityCheck={props.onClickEligibilityCheck}
        />
      </DrawerBody>
      <DrawerCloseButton size={"lg"} />
    </DrawerContent>
  );
};

export default IntakePatientProfileDrawer;
