import { ExternalLinkIcon, PhoneIcon } from "@chakra-ui/icons";
import { Divider, Flex, Heading, Link, List, ListItem, Text } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { useRouter } from "@uirouter/react";
import React from "react";
import { match, P } from "ts-pattern";
import { Messages, ResponseOf } from "../../../core/api";
import { CommCenterTicketId } from "../../../shared/schema/schema";
import { fmap } from "../../../shared/utils";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { formatDirection } from "../../communication/utils/communication-utils";

interface IntakePatientCommunicationLogProps {
  communicationLog: ResponseOf<"get", "./comm_center/tickets/patients/:patientId">["tickets"];
}

const IntakePatientCommunicationLog = (props: IntakePatientCommunicationLogProps) => {
  return (
    <Flex direction="column" gap={4}>
      <Heading variant="profileHeaderUnderlined" as="h2" size="md">
        Communication Log
      </Heading>
      {props.communicationLog.length ? (
        <List display="flex" gap={4} flexDirection="column">
          {props.communicationLog.map((log) => {
            return (
              log.callSessionDetails !== null && (
                <>
                  <ListItem key={log.id}>
                    <IntakePatientCommunicationLogRow
                      ticketId={log.id}
                      callDate={log.createdAt}
                      callSessionDetails={log.callSessionDetails}
                    />
                  </ListItem>
                  <Divider variant="dashed"></Divider>
                </>
              )
            );
          })}
        </List>
      ) : (
        <Text> Looks like there are no communication logs yet.</Text>
      )}
    </Flex>
  );
};

interface IntakePatientCommunicationLogRowProps {
  ticketId: CommCenterTicketId;
  callDate: Instant;
  callSessionDetails: Messages["CallSessionDetails"];
}

const getCallParticipantName = (participant: Messages["CallSessionParticipantRoles"]) => {
  return match(participant)
    .with(
      { type: P.union("Caregiver", "AgencyMember", "Patient") },
      (v) => v.firstName + " " + v.lastName
    )
    .with({ type: "NotIdentified" }, () => "Not Identified")
    .exhaustive();
};

const IntakePatientCommunicationLogRow = (props: IntakePatientCommunicationLogRowProps) => {
  const { stateService } = useRouter();
  const { ticketId, callDate } = props;
  const { direction, sessionParticipantRoles, sessionMedia } = props.callSessionDetails;

  const caller = sessionParticipantRoles.find(
    (p: Messages["CallSessionParticipantRoles"]) => p.role === "Caller"
  );
  const recipient = sessionParticipantRoles.find(
    (p: Messages["CallSessionParticipantRoles"]) => p.role === "Recipient"
  );

  const handleClickTicketLink = (ticketId: CommCenterTicketId) => {
    stateService.go("app.commcenter_ticket", { ticketId: ticketId });
  };

  const callerName = caller !== undefined ? getCallParticipantName(caller) : null;
  const recipientName =
    recipient !== undefined ? getCallParticipantName(recipient) : ", missed call";

  const summaryText =
    sessionMedia !== null && sessionMedia.summary !== null
      ? sessionMedia.summary
      : "No summary available";

  return (
    <Flex direction="column" gap={3}>
      <Flex justifyContent="space-between">
        <Flex justifyContent="space-between" gap={3} alignItems={"center"}>
          <PhoneIcon color={"green.400"} />
          <Text>{formatDirection(direction)}</Text>
          <Divider orientation="vertical" borderColor={"black"} />
          <Text>{fmap(callDate, (v) => dateFormatter.toDateTime(v))}</Text>

          {callerName !== null && (
            <>
              <Divider orientation="vertical" borderColor={"black"} />
              <Text>{`${callerName} called ${recipientName}`}</Text>
            </>
          )}
        </Flex>
        <Flex>
          <Link onClick={() => handleClickTicketLink(ticketId)} color={"blue.600"}>
            <Flex alignItems={"center"}>
              See ticket
              <ExternalLinkIcon mx="2px" />
            </Flex>
          </Link>
        </Flex>
      </Flex>
      <Text>Call summary - {summaryText}</Text>
    </Flex>
  );
};

export default IntakePatientCommunicationLog;
