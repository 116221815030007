import { Drawer, DrawerOverlay, useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { BodyOf } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { NoteId, PatientId } from "../../../shared/schema/schema";
import { queryKeys } from "../../../shared/query-keys";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import LoadingPage from "../../../shared/components/LoadingPage";
import IntakePatientProfileDrawer from "./IntakePatientProfileDrawer";
import { z } from "zod";
import { zPathParamId } from "../../../shared/utils/zod";
import usePathParams from "../../../shared/hooks/usePathParams";
import { useRouter } from "@uirouter/react";

const zPathParams = z.object({
  patientId: zPathParamId(PatientId).optional(),
});

const IntakePatientProfileRoute = () => {
  const { patientId } = usePathParams(zPathParams);
  const { stateService } = useRouter();

  const handleOpen = () => {
    stateService.go("app.patients.intake.profile", {
      patientId,
    });
  };

  const handleClose = () => {
    stateService.go("app.patients.intake");
  };

  return (
    <Drawer placement="right" isOpen={patientId !== undefined} onClose={handleClose} size="xl">
      <DrawerOverlay />
      {patientId !== undefined && (
        <IntakePatientProfile patientId={patientId} onClose={handleClose} onOpen={handleOpen} />
      )}
    </Drawer>
  );
};

interface Props {
  patientId: PatientId;
  onClose: () => void;
  onOpen: () => void;
}

const IntakePatientProfile = (props: Props) => {
  const { api } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();

  const intakeProfile = useQuery({
    queryKey: queryKeys.patientIntake.get(props.patientId!),
    queryFn: () => {
      return api.get("./patients/:patientId/intake", {
        path: {
          patientId: props.patientId!,
        },
      });
    },
    onError: (error) => {
      toast({
        title: `Error in fetching intake profile data.`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const { mutate: handleEditPatientIntakeProfileMutation } = useMutation({
    mutationKey: ["edit-patient-intake-profile"],
    mutationFn: (body: BodyOf<"put", "./patients/:patientId/intake">) =>
      api.put("./patients/:patientId/intake", {
        body,
        path: {
          patientId: props.patientId!,
        },
      }),
    onSuccess: () => {
      toast({
        title: "Success! patient's details were updated",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });

      queryClient.invalidateQueries(queryKeys.patientIntake.get(props.patientId!));
    },
    onError: (error) => {
      toast({
        title: `Error in updating the patient's details`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const markNoteAsDone = useMutation({
    mutationFn: (noteId: NoteId) => {
      return api.put("./notes/:noteId/status", {
        path: { noteId },
        body: { status: "DONE" },
      });
    },
    onSuccess: () => {
      toast({
        title: "Successfuly marked note as done",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });

      queryClient.invalidateQueries(queryKeys.patientIntake.get(props.patientId!));
    },
    onError: (error) => {
      toast({
        title: `Error in marking note as done.`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const intakeStatuses = useQuery({
    queryKey: queryKeys.patientIntake.intakeStatus(),
    keepPreviousData: true,
    queryFn: () => api.get("./patient_intake/intake_status", {}),
  });

  const intakePlans = useQuery({
    queryKey: queryKeys.patientIntake.intakePlan(),
    keepPreviousData: true,
    queryFn: () => api.get("./patient_intake/intake_plan", {}),
  });

  const eligibilityChecks = useQuery({
    queryKey: ["get-eligibility-checks"],
    queryFn: () =>
      api.get("./eligibility_checks", {
        query: {},
      }),
    onError: (error) => {
      toast({
        title: `Error in fetching eligibility checks data.`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const communicationLog = useQuery({
    queryKey: queryKeys.patientIntake.communicationLog(props.patientId),
    keepPreviousData: true,
    queryFn: () =>
      api.get("./comm_center/tickets/patients/:patientId", {
        path: {
          patientId: props.patientId!,
        },
      }),
    select: (data) => data.tickets.filter((ticket) => ticket.callSessionDetails !== null),
    onError: (error) => {
      toast({
        title: `Error in fetching communication log data.`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const { mutate: handleRunEligibilityCheckMutation } = useMutation({
    mutationKey: ["run-eligibility-check"],
    mutationFn: () =>
      api.post("./patients/:patientId/check_eligibility", {
        path: {
          patientId: props.patientId!,
        },
      }),
    onSuccess: (_response) => {
      toast({
        title: "Successfuly run eligibility check",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });

      queryClient.invalidateQueries(["get-eligibility-checks"]);
    },
    onError: (error) => {
      toast({
        title: `Error running eligibility check`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const handleClickAddNewNote = () => {
    props.onClose();
    window.dispatchEvent(
      new CustomEvent("from-webapp-react", {
        detail: {
          type: "navigate",
          payload: {
            entity: "Note",
            id: props.patientId,
            callback: () => {
              queryClient.invalidateQueries(queryKeys.patientIntake.get(props.patientId));
              props.onOpen();
            },
          },
        },
      })
    );
  };

  if (
    intakeProfile.isLoading ||
    intakePlans.isLoading ||
    intakeStatuses.isLoading ||
    communicationLog.isLoading ||
    intakeProfile.isFetching ||
    intakePlans.isFetching ||
    intakeStatuses.isFetching ||
    communicationLog.isFetching
  ) {
    return <LoadingPage />;
  }

  if (
    intakeProfile.isError ||
    intakePlans.isError ||
    intakeStatuses.isError ||
    communicationLog.isError
  ) {
    return <div>Error</div>;
  }

  if (!intakeProfile.data || !intakePlans.data || !intakeStatuses.data || !communicationLog.data) {
    return <div>Something went wrong</div>;
  }

  return (
    <IntakePatientProfileDrawer
      intakeProfile={intakeProfile.data.patient}
      intakePlans={intakePlans.data.intakePlans}
      intakeStatuses={intakeStatuses.data.intakeStatuses}
      communicationLog={communicationLog.data}
      eligibilityCheckResults={eligibilityChecks.data?.records ?? []}
      onClickEligibilityCheck={handleRunEligibilityCheckMutation}
      onClickAddNewNote={handleClickAddNewNote}
      onEditPatientIntake={handleEditPatientIntakeProfileMutation}
      onMarkNoteAsDone={markNoteAsDone.mutate}
      onCloseIntakeProfile={props.onClose}
    />
  );
};

export default IntakePatientProfileRoute;
