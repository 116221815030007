export function getMedian(arr: number[]) {
  if (arr.length === 0) {
    return;
  }

  arr.sort((a, b) => {
    return a - b;
  });

  const half = Math.floor(arr.length / 2);

  if (arr.length % 2 === 1) return arr[half];

  return (arr[half - 1] + arr[half]) / 2.0;
}
