import {
  Box,
  Center,
  Flex,
  Heading,
  IconButton,
  IconProps,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useInterval,
} from "@chakra-ui/react";
import { ChronoUnit, Duration, Instant } from "@js-joda/core";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { Messages } from "../../../../core/api";
import Dialpad from "../../../../shared/components/DialPad";
import useActiveCall, { ActiveCallData } from "../../../../shared/hooks/useActiveCall";
import useApi from "../../../../shared/hooks/useApi";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import useSingleDigitDialPad from "../../../../shared/hooks/useSingleDigitDialPad";
import useSocketEvent from "../../../../shared/hooks/useSocketEvent";
import BaselinePersonAddAlt1Icon from "../../../../shared/icons/BaselinePersonAddAlt1Icon";
import DialpadIcon from "../../../../shared/icons/DialpadIcon";
import DotsVerticalIcon from "../../../../shared/icons/DotsVerticalIcon";
import HeadphonesIcon from "../../../../shared/icons/HeadphonesIcon";
import MicMuteFillIcon from "../../../../shared/icons/MicMuteFillIcon";
import PhoneHangupIcon from "../../../../shared/icons/PhoneHangupIcon";
import ShareIcon from "../../../../shared/icons/ShareIcon";
import {
  AgencyMemberId,
  CallSessionId,
  CallSessionParticipantId,
} from "../../../../shared/schema/schema";
import {
  bargeInCall,
  hangupCall,
  muteCall,
  sendDtmfTone,
} from "../../../../shared/utils/call-center";
import { durationFormatter } from "../../../../shared/utils/duration-formatter";
import { phoneFormatter } from "../../../../shared/utils/phone-formatter";
import useDialerTabsContext from "../../hooks/useDialerTabsContext";
import CallTicketParticipantCard from "../CallTicketParticipantCard";
import DialerTabs from "../DialerTabs";
import LiveBadge from "../LiveBadge";
import CallNoteCard from "./CallNoteCard";
import CallNotesSection from "./CallNotesSection";
import CallTicketColumn from "./helpers/CallTicketColumn";
import CallTicketEventCard from "./helpers/CallTicketEventCard/CallTicketEventCard";
import CallTicketRow from "./helpers/CallTicketRow";
import useCallTicketNotes from "./hooks/useCallTicketNotes";

const ICON_PROPS: IconProps = {
  cursor: "var(--icon-cursor)",
  boxSize: "var(--icon-box-size)",
  color: "var(--icon-color)",
};

interface Props {
  activeTicket: Messages["CommCenterTicket"] & {
    callSessionDetails: Messages["CallSessionDetails"];
  };
  notesSectionData: ReturnType<typeof useCallTicketNotes>;
}

const isEnterEvent = (
  x: Messages["CallSessionParticipantEvent"]
): x is Messages["CallSessionParticipantEvent"] & { data: Messages["Enter"] } =>
  x.data.eventStatus === "ENTER";

function getCallDurationSeconds(callSessionDetails: Messages["CallSessionDetails"]): number {
  if (callSessionDetails.sessionParticipantEvents.length === 0) {
    return 0;
  }
  const firstEventUntil = callSessionDetails.sessionParticipantEvents[0].createdAt.until(
    Instant.now(),
    ChronoUnit.SECONDS
  );
  if (callSessionDetails.direction === "OUTBOUND") {
    return firstEventUntil;
  }
  const firstEnterEvent = callSessionDetails.sessionParticipantEvents.find(isEnterEvent);
  if (firstEnterEvent !== undefined) {
    return firstEnterEvent.createdAt.until(Instant.now(), ChronoUnit.SECONDS);
  }
  return firstEventUntil;
}

const LiveCallTicket = (props: Props) => {
  const { api } = useApi();
  const { agencyMember } = useAuthData();
  const { activeCall, setActiveCall } = useActiveCall();
  const [addToCallContext, setAddToCallContext] = useState<"Add" | "Transfer">("Add");
  const singleDigitDialer = useDisclosure();
  const [durationSeconds, setDurationSeconds] = useState(
    getCallDurationSeconds(props.activeTicket.callSessionDetails)
  );
  const [dialerContext, setDialerContext] = useDialerTabsContext();
  const { state: singleDigitDialPadState } = useSingleDigitDialPad({
    onClickDigit: (digit, acc) => {
      console.log(`got new digit: ${digit}`);
      console.log(`accInput: ${acc}`);
      sendDtmfTone(digit);
    },
  });

  const inviteAgencyMemberToCall = useMutation({
    mutationFn: (params: { callSessionId: CallSessionId; agencyMemberId: AgencyMemberId }) =>
      api.post("./call_center/:callSessionId/invite_agency_member/:invitedAgencyMemberId", {
        path: {
          callSessionId: params.callSessionId,
          invitedAgencyMemberId: params.agencyMemberId,
        },
      }),
    onSuccess: () => dialerDisclosure.onClose(),
  });

  const invitePhoneNumberToCall = useMutation({
    mutationFn: (params: { callSessionId: CallSessionId; number: string }) =>
      api.post("./call_center/:callSessionId/invite_not_agency_member/:destination", {
        path: {
          callSessionId: params.callSessionId,
          destination: params.number,
        },
      }),
    onSuccess: () => dialerDisclosure.onClose(),
  });

  const removeParticipantFromCall = useMutation({
    mutationFn: (params: {
      callPartifipantId: CallSessionParticipantId;
      callSessionId: CallSessionId;
    }) =>
      api.post("./call_center/:callSessionId/remove/:participantIdToRemove", {
        path: {
          callSessionId: params.callSessionId,
          participantIdToRemove: params.callPartifipantId,
        },
      }),
  });

  const dialerDisclosure = useDisclosure();

  const { callSessionDetails } = props.activeTicket;

  useInterval(() => {
    setDurationSeconds((prev) => {
      return prev + 1;
    });
  }, 1000);

  const handleClickHangup = () => {
    hangupCall(() => {
      if (activeCall !== null) {
        setActiveCall({ ...activeCall, status: "Completed" });
      }
      console.log("hang up success");
    });
  };

  const isSessiongJoined = (activeCall: ActiveCallData | null): activeCall is ActiveCallData => {
    return (
      activeCall?.ticketId === props.activeTicket.id &&
      (activeCall.status === "Active" || activeCall.status === "Ringing")
    );
  };

  const handleClickDialer = () => {
    singleDigitDialer.onOpen();
  };

  const handleClickAddToCall = () => {
    setAddToCallContext("Add");
    setDialerContext({
      hideAgencyMembers: false,
      hideCaregivers: false,
      hidePatients: false,
      hidePhoneNumber: false,
    });
    dialerDisclosure.onOpen();
  };

  const handleClickTransferCall = () => {
    setAddToCallContext("Transfer");
    setDialerContext({
      hideAgencyMembers: false,
      hideCaregivers: true,
      hidePatients: true,
      hidePhoneNumber: false,
    });
    dialerDisclosure.onOpen();
  };

  useSocketEvent({
    key: "CallCenterBargeIn",
    onEvent: (event) => {
      if (activeCall === null && event.callSessionId === callSessionDetails.sessionId) {
        setActiveCall({
          status: "Active",
          ticketId: props.activeTicket.id,
          callSessionId: callSessionDetails.sessionId,
          participantId: event.participantId,
          additionalData: event.additionalData,
          sessionToken: callSessionDetails.sessionUUID ?? "",
          liveCallModalOpen: false,
          isMuted: false,
        });
      }
    },
  });

  const handleClickJoinCall = () => {
    if (callSessionDetails.sessionUUID !== null) {
      bargeInCall(callSessionDetails.sessionUUID, {
        onSessionConnected: () => {
          //
        },
        onSessionTerminated: () => {
          //
        },
      });
    }
  };

  const handleClickMute = () => {
    if (activeCall === null) {
      return;
    }
    muteCall(() => {
      setActiveCall({
        ...activeCall,
        isMuted: true,
      });
    });
  };

  const handleClickDial = (number: string) => {
    if (activeCall === null) {
      return;
    }
    switch (addToCallContext) {
      case "Add":
        return invitePhoneNumberToCall.mutate({
          callSessionId: activeCall.callSessionId,
          number,
        });
      case "Transfer":
        return;
    }
  };

  const handlClickDialAgencyMember = (agencyMemberId: AgencyMemberId) => {
    if (activeCall === null) {
      return;
    }
    switch (addToCallContext) {
      case "Add":
        return inviteAgencyMemberToCall.mutate({
          agencyMemberId,
          callSessionId: activeCall.callSessionId,
        });
      case "Transfer":
        return;
    }
  };

  const handleRemoveParticipantFromCall = (participantId: CallSessionParticipantId) => {
    if (activeCall !== null) {
      removeParticipantFromCall.mutate({
        callPartifipantId: participantId,
        callSessionId: activeCall.callSessionId,
      });
    }
  };

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      backgroundSize="60%"
      borderRadius="0 12px 12px 0"
      height="100%"
      gap={2}
      padding={2}
    >
      <CallTicketColumn>
        <CallTicketRow>
          <Flex py={"10px"} flexDirection={"column"} gap={"50px"}>
            <Flex flexDirection={"column"} gap={4} align={"center"}>
              <Text fontSize={"5xl"}>
                {durationFormatter.hours(Duration.ofSeconds(durationSeconds))}
              </Text>
              <Text>
                {phoneFormatter.isValidNumber(callSessionDetails.externalPhoneNumber)
                  ? phoneFormatter.formatNational(callSessionDetails.externalPhoneNumber)
                  : callSessionDetails.externalPhoneNumber}
              </Text>
              <LiveBadge />
            </Flex>
            <Box
              sx={{ "--icon-box-size": "40px", "--icon-color": "gray", "--icon-cursor": "pointer" }}
            >
              {isSessiongJoined(activeCall) ? (
                <JoinedCallActions
                  isMuted={activeCall.isMuted}
                  onClickHangup={handleClickHangup}
                  onClickDialer={handleClickDialer}
                  onClickAddToCall={handleClickAddToCall}
                  onClickTransferCall={handleClickTransferCall}
                  onClickMute={handleClickMute}
                />
              ) : (
                <NotjoinedCallActions onClickJoin={handleClickJoinCall} />
              )}
            </Box>
          </Flex>
        </CallTicketRow>
        <CallTicketRow>
          <CallTicketRow.Header>Call Notes</CallTicketRow.Header>
          <CallTicketRow.Content>
            <CallNotesSection {...props.notesSectionData} ticketId={props.activeTicket.id} />
            {callSessionDetails.sessionNotes.map((note) => (
              <CallNoteCard key={note.id} note={note} />
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
      <CallTicketColumn>
        <CallTicketRow>
          <CallTicketRow.Header>Call Parties</CallTicketRow.Header>
          <CallTicketRow.Content>
            {callSessionDetails.sessionParticipantRoles.map((role, i) => (
              <CallTicketRow.ContentRow key={`${role.id}`}>
                <CallTicketParticipantCard
                  role={role}
                  index={i}
                  actions={getRoleActions(agencyMember.id, role, handleRemoveParticipantFromCall)}
                />
              </CallTicketRow.ContentRow>
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow>
          <CallTicketRow.Header>Call Events</CallTicketRow.Header>
          <CallTicketRow.Content>
            {callSessionDetails.sessionParticipantEvents
              .slice()
              .sort((a, b) => a.id - b.id)
              .map((event) => (
                <CallTicketRow.ContentRow key={`${event.id}`}>
                  <CallTicketEventCard event={event} />
                </CallTicketRow.ContentRow>
              ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>

      <Modal isOpen={singleDigitDialer.isOpen} onClose={singleDigitDialer.onClose} isCentered>
        <ModalOverlay zIndex={10001} />
        <ModalContent containerProps={{ zIndex: 10001 }} display={"flex"} paddingY={6}>
          <ModalCloseButton />
          <Center as={Flex} flexDirection={"column"} h={"full"}>
            <ModalHeader>
              <Heading>Enter Input</Heading>
            </ModalHeader>
            <Dialpad state={singleDigitDialPadState} />
          </Center>
        </ModalContent>
      </Modal>
      <Modal {...dialerDisclosure} size={"xl"}>
        <ModalOverlay zIndex={10001} />
        <ModalContent containerProps={{ zIndex: 10001 }}>
          <ModalCloseButton />
          <ModalBody minH={"500px"}>
            <ModalHeader textAlign={"center"}>
              <Heading>Add To Call</Heading>
            </ModalHeader>
            <DialerTabs
              context={dialerContext}
              isCalling={false}
              onClickDial={handleClickDial}
              onClickAgencyMember={handlClickDialAgencyMember}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

const JoinedCallActions = (props: {
  isMuted: boolean;
  onClickHangup: () => void;
  onClickDialer: () => void;
  onClickAddToCall: () => void;
  onClickTransferCall: () => void;
  onClickMute: () => void;
}) => {
  return (
    <Flex w={"full"} flexDirection={"column"} justifyContent={"center"} gap={2}>
      <Flex direction={"row"} justifyContent={"center"} gap={12}>
        <MicMuteFillIcon
          {...ICON_PROPS}
          color={props.isMuted ? "blue.500" : ICON_PROPS.color}
          onClick={props.onClickMute}
        />
        <Box></Box>
        <DialpadIcon {...ICON_PROPS} onClick={props.onClickDialer} />
      </Flex>
      <Flex direction={"row"} justifyContent={"center"} gap={6}>
        <BaselinePersonAddAlt1Icon {...ICON_PROPS} onClick={props.onClickAddToCall} />
        <PhoneHangupIcon {...ICON_PROPS} onClick={props.onClickHangup} />
        <ShareIcon {...ICON_PROPS} onClick={props.onClickTransferCall} />
      </Flex>
    </Flex>
  );
};

const NotjoinedCallActions = (props: { onClickJoin: () => void }) => {
  return (
    <Flex w={"full"} flexDirection={"row"} justifyContent={"center"} gap={7}>
      <HeadphonesIcon {...ICON_PROPS} />
      <BaselinePersonAddAlt1Icon onClick={props.onClickJoin} {...ICON_PROPS} />
    </Flex>
  );
};

const getRoleActions = (
  agencyMemberId: AgencyMemberId,
  role: Messages["CallSessionParticipantRoles"],
  onRemoveParticipantFromCall: (id: CallSessionParticipantId) => void
): React.ReactNode | null => {
  if (
    !role.isActiveOnCall ||
    (role.type === "AgencyMember" && role.agencyMemberId === agencyMemberId)
  ) {
    return null;
  }
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton size="sm" icon={<DotsVerticalIcon />} aria-label={"actions"} />
      </PopoverTrigger>
      <PopoverContent>
        <List>
          <ListItem onClick={() => onRemoveParticipantFromCall(role.id)}>Remove from call</ListItem>
        </List>
      </PopoverContent>
    </Popover>
  );
};

export default LiveCallTicket;
