import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { TicketSummaryDisclosureState } from "../hooks/useTicketSummaryDisclosure";
import { getIconBySatisfactionRank } from "../utils/communication-utils";

const TicketSummaryDisclosureContent = (props: TicketSummaryDisclosureState) => {
  return (
    <Flex direction={"column"} gap={4}>
      <Heading size="sm">Summary</Heading>
      <Box>
        <Text>{props.summaryText}</Text>
      </Box>
      <Divider />
      <Box>
        <Flex direction={"row"} justifyContent={"space-between"}>
          <Heading size="sm">Satisfaction</Heading>
          {getIconBySatisfactionRank(props.satisfactionRank)}
        </Flex>
        <Text>{props.satisfactionText}</Text>
      </Box>
    </Flex>
  );
};

export default TicketSummaryDisclosureContent;
