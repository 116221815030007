import { CaregiverId, VisitBroadcastId, VisitInstanceId } from "../../../../scripts/messages/ids";
import { VisitInstance } from "../../../../scripts/messages/visit";
import { VisitBroadcastService } from "../../visitBroadcast.service";
import { VisitBroadcastPartialAssingParams } from "../../visitBroadcast.types";
import "./caregivers-visits-requests-table-non-multiple.component.scss";
import {
  CaregiverVisitRequest,
  CaregiverVisitsRequestsTableNonMultipleBindings,
} from "./caregivers-visits-requests-table-non-multiple.types";
import { mapVisitInstancesTimes } from "../visit-table-common.utils";
import { Shift } from "../visit-table-common.types";

//! @ngInject
class caregiversVisitsRequestsTableNonMultipleCtrl
  implements ng.IComponentController, CaregiverVisitsRequestsTableNonMultipleBindings
{
  caregiverVisitRequests!: CaregiverVisitRequest[];
  visitInstances!: VisitInstance[];
  visitBroadcastId!: VisitBroadcastId;
  startFlyToCaregiver!: (caregiverId: CaregiverId) => void;
  stopFlyToCaregiver!: () => void;
  closeModal!: () => void;
  updateEngagements!: () => void;
  handleClickChat!: (caregiverId: CaregiverId) => void;

  constructor(
    private $filter: ng.IFilterService,
    private $rootScope: ng.IRootScopeService,
    private toaster: toaster.IToasterService,
    private mfModal: any,
    private visitBroadcastService: VisitBroadcastService
  ) {}

  $onInit() {
    this.visitInstances = mapVisitInstancesTimes(this.visitInstances);
  }

  buildRejectVisitBodyParams(caregiverId: CaregiverId, caregiverRequestedShifts: Shift[]) {
    const visitInstancesIds: VisitInstanceId[] = [];

    for (const shift of caregiverRequestedShifts) {
      if (shift.type === "PATTERN") {
        visitInstancesIds.push(...shift.visitInstancesIds);
      } else {
        visitInstancesIds.push(shift.visitInstanceId);
      }
    }

    return {
      visitInstances: visitInstancesIds,
    };
  }

  assignShiftsToCaregivers(caregiverId: CaregiverId) {
    const requestBody: VisitBroadcastPartialAssingParams = {
      assignParams: [
        {
          caregiverId,
          visitInstancesIds: this.visitInstances.map((visitInstance) => visitInstance.id),
        },
      ],
    };

    const assignCaregiverToVisits = () =>
      this.$rootScope.isOnboardingAgency
        ? this.visitBroadcastService.requestAssignmentCaregiversToVisit(
            this.visitBroadcastId,
            requestBody
          )
        : this.visitBroadcastService.assignCaregiversToVisit(this.visitBroadcastId, requestBody);
    assignCaregiverToVisits().then(
      (res) => {
        if (res.data.assignWithIncreasedCaregiverOvertime) {
          this.toaster.pop({
            type: "warning",
            title: "Warning",
            body: `Successfully assigned caregiver with increased caregiver overtime`,
          });
        } else {
          this.toaster.pop("success", "Successfully assigned caregiver");
        }
        this.$rootScope.$emit("refresh_visits");
        this.closeModal();
      },
      (err) => {
        let errorMessage = "Failed to assign caregiver";
        if (err.status === 403) {
          errorMessage = "Not permitted to increase caregiver overtime.";
        }
        this.toaster.pop("error", "Oops...", errorMessage);
      }
    );
  }

  openShouldRejectModal(caregiverId: CaregiverId, caregiverRequestedShifts: Shift[]) {
    const modal = this.mfModal.create({
      subject: "Are You Sure?",
      variant: "warning",
      message:
        "Clicking 'Reject' will result in rejecting the entire request (all of the caregiver's requested shifts).",
      cancelLabel: "I changed my mind",
      confirmLabel: "Reject Request",
      showInput: false,
      layoutOrder: ["message"],
      hideCancelButton: false,
      preventBackdropClose: true,
      onConfirm: () => {
        const requestBody = this.buildRejectVisitBodyParams(caregiverId, caregiverRequestedShifts);

        this.visitBroadcastService
          .rejectCaregiverVisitRequest(caregiverId, this.visitBroadcastId, requestBody)
          .then((_res) => {
            modal.close();
            this.toaster.pop("success", "Successfully rejected caregiver's request.");
            this.$rootScope.$broadcast("visit_changed", { visitId: this.visitBroadcastId });
          })
          .catch((_err) => {
            this.toaster.pop("error", "Oops...", "Failed to reject the caregiver's request.");
          });
      },
    });
  }

  onClickChat(caregiverId) {
    if (this.$rootScope.isOnboardingAgency) {
      this.handleClickChat(caregiverId);
    } else {
      this.$rootScope.openAgencyChat(caregiverId);
    }
  }
}

export const caregiverVisitRequestsTableNonMultiple = {
  templateUrl:
    "admin/modules/visit-broadcast/components/caregivers-visits-requests-table-non-multiple/caregivers-visits-requests-table-non-multiple.component.html",
  controller: caregiversVisitsRequestsTableNonMultipleCtrl,
  controllerAs: "ctrl",
  bindings: {
    caregiverVisitRequests: "<",
    visitInstances: "<",
    visitBroadcastId: "<",
    closeModal: "&",
    updateEngagements: "&",
    startFlyToCaregiver: "<",
    stopFlyToCaregiver: "<",
    handleClickChat: "=",
  },
};
