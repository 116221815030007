import { WorkflowTaskInstanceId } from "../../shared/schema/schema";
import { createQueryKeys } from "../../shared/utils/create-query-keys";

export const workflowKeys = createQueryKeys("workflow", {});

export const workflowTaskKeys = createQueryKeys("workflow_task", {
  search: (params: Record<string, unknown>) => params,
  item: (id: WorkflowTaskInstanceId) => id,
  skills: null,
});
