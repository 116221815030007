import { SocketEvent, SocketPayloadMap } from "../../types/socket";
import { useWebappChanneDispatchEvent } from "./useWebappChannelEvent";

const useMultipleSocketEvent = <$SocketEventKey extends SocketEvent["type"]>(params: {
  keys: $SocketEventKey[];
  onEvent: (event: SocketPayloadMap[$SocketEventKey], type: $SocketEventKey) => void;
}) => {
  useWebappChanneDispatchEvent({
    eventName: "SOCKET_EVENT_RECEIVED",
    onEvent: (value) => {
      if (params.keys.includes(value.type as $SocketEventKey)) {
        params.onEvent(
          value.payload as SocketPayloadMap[$SocketEventKey],
          value.type as $SocketEventKey
        );
      }
    },
  });
};

export default useMultipleSocketEvent;
