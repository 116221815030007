import { Box, BoxProps, Flex, forwardRef, Heading, Progress } from "@chakra-ui/react";
import React from "react";

interface Props {
  isLoading?: boolean;
  children: React.ReactNode;
  as?: React.ElementType;
}

const Page = (props: Props) => {
  return (
    <Root as={props.as}>
      <div style={{ opacity: props.isLoading === true ? 1 : 0 }}>
        <Progress size="xs" isIndeterminate />
      </div>
      <Flex direction="column" m={3} rounded={8} bg="white" boxShadow="sm" height="full" flex={1}>
        {props.children}
      </Flex>
    </Root>
  );
};

const Root = forwardRef<BoxProps, "div">((props, ref) => {
  return <Flex ref={ref} direction="column" h="full" minH="calc(100vh - 170px)" {...props} />;
});

const Header = forwardRef<BoxProps, "div">((props, ref) => {
  return <Box ref={ref} p={8} {...props} />;
});

const Title = forwardRef<BoxProps, "h2">((props, ref) => {
  return <Heading ref={ref} size="lg" {...props} />;
});

const Filters = forwardRef<BoxProps, "div">((props, ref) => {
  return <Box ref={ref} p={8} {...props} />;
});

const Content = forwardRef<BoxProps, "div">((props, ref) => {
  return <Box ref={ref} p={6} h="full" {...props} />;
});

export default Object.assign(Page, {
  Header,
  Title,
  Filters,
  Content,
});
