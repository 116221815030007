import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import { Button, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../core/api";
import { NoteId } from "../../../shared/schema/schema";

interface Props {
  notes: Messages["IntakePatientProfile"]["notes"];
  onClickNewNote: () => void;
  onClickMarkAsDone: (noteId: NoteId) => void;
}

const IntakePatientProfileNotesSection = (props: Props) => {
  return (
    <Flex
      direction="column"
      gap={4}
      border="1px"
      borderRadius="10px"
      borderColor="lightgray"
      width="700px"
      background="#f2f5f3"
    >
      <Heading
        variant="profileHeaderUnderlined"
        as="h1"
        size="md"
        marginLeft="15px"
        marginTop="15px"
      >
        Patient Notes
      </Heading>
      <Flex direction="column" gap={5} marginLeft="15px" maxHeight="150px" overflowY="scroll">
        {props.notes.length > 0 ? (
          props.notes.map((note) => (
            <PatientIntakeNote
              key={`note-${note.id}`}
              note={note}
              onClickMarkAsDone={props.onClickMarkAsDone}
            />
          ))
        ) : (
          <NoNotesContainer />
        )}
        <Divider size="md" variant="solid" colorScheme="blackAlpha" />
      </Flex>
      <Button
        width="100px"
        marginBottom="15px"
        colorScheme="blue"
        leftIcon={<AddIcon />}
        aria-label={"Add new note"}
        onClick={props.onClickNewNote}
        variant="link"
      >
        {" "}
        Add New{" "}
      </Button>
    </Flex>
  );
};

export default IntakePatientProfileNotesSection;

interface PatientIntakeNoteProps {
  note: Messages["PatientNote"];
  onClickMarkAsDone: (noteId: NoteId) => void;
}

const PatientIntakeNote = (props: PatientIntakeNoteProps) => {
  const getStatusTextDisplay = (status: string) => {
    switch (status) {
      case "IN_PROGRESS": {
        return {
          text: "In Progress",
          color: "orange",
        };
      }
      case "VIEWED": {
        return {
          text: "Viewed",
          color: "blue",
        };
      }
      case "DONE": {
        return {
          text: "Done",
          color: "green",
        };
      }
      default: {
        return {
          text: "No Status",
          color: "gray",
        };
      }
    }
  };

  return (
    <Flex direction="row" justifyContent="space-between" alignItems="center">
      <Text maxWidth="300px" width="300px">
        {parseNoteText(props.note.noteRichText)}
      </Text>
      <Text alignSelf="center" colorScheme={getStatusTextDisplay(props.note.status).color}>
        {" "}
        {getStatusTextDisplay(props.note.status).text}{" "}
      </Text>
      <Button
        marginRight="15px"
        aria-label={"Mark as done"}
        leftIcon={<CheckIcon />}
        variant="link"
        colorScheme="green"
        onClick={() => props.onClickMarkAsDone(props.note.id)}
      >
        Mark as done
      </Button>
    </Flex>
  );
};

const parseNoteText = (noteWithHtml: string | null) => {
  return noteWithHtml?.replace(/<[^>]+>/g, "");
};

const NoNotesContainer = () => {
  return (
    <Flex direction="column">
      <Text> Looks like there are no notes yet.</Text>
      <Text> Add a new one!</Text>
    </Flex>
  );
};
