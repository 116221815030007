//! @ngInject
export function filterFieldValue(itemSearchPageManager) {
    return {
      restrict: "E",
      transclude: true,

      templateUrl: "admin/views/filter-field-value-directive.html",
      scope: {
        filter: "="
      },
      link: function($scope, $rootScope) {
        $scope.customListSelectionSettings = {
          styleActive: true,
          singleSelection: true,
          selectionLimit: 1,
          smartButtonMaxItems: 1,
          closeOnSelect: true,
          showCheckAll: false,
          showUncheckAll: false
    };
        var scope = $scope;
        $scope.filterEvents = {
          onItemSelect: function (item) {
            // alert(1);


            if (item['$selectedModel']){
              item.value = [];
              for (let j = 0; j < item['$selectedModel'].length; j++) {
                  let selectedArrayItem = item["$selectedModel"][j];
                  item.value.push(selectedArrayItem.id);
              }
              if (item.value.length === 0){
                  filters.splice(i);
              }
          }
          },
          onItemDeselect(item) {

              // $scope.selectedPendingCertificationLabel = null;
          },
          onDeselectAll() {
              // $scope.selectedPendingCertificationLabel = null;
          }
      }

        this.sortableOptions = {
          handle: '> .myHandle'
        }
        this.hideValueOperators = ['IS NULL', 'NOT IS NULL'];
        this.altInputFormats = ['MM/dd/yyyy'];
        this.datePickerOptions = {
          locale: {
            format: "MM/DD/YYYY",
          },
          startingDay: "Sunday"
        };
        this.timePeriodOptions = ["DAY", "WEEK", "MONTH", "YEAR"];
        this.timePeriodIntervalOptions = ["DAYS", "WEEKS", "MONTHS", "YEARS"];
        this.timePeriodRelativeIntervalOptions = ["MINUTES", "HOURS", "DAYS"];
        this.timePeriodOperators = ["LAST X", "THIS X", "NEXT X", "LAST N X", "NEXT N X"];
        this.timePeriodIntervalOperators = ["LAST N X", "NEXT N X"];
        this.timePeriodRelativeIntervalOperators = ["LAST REL N X", "NEXT REL N X"];
        this.nowRelativeTimeComparisonOperator = "NOW REL TIME COMPARE";

        this.showOperator = (operator) => {
          return (
            // Not hidden operator
            !(
              operator.logicalOperator === this.nowRelativeTimeComparisonOperator
              || this.timePeriodRelativeIntervalOperators.includes(operator.logicalOperator)
            )
            // Or is hidden operator and is onboarding agency
            || $rootScope.isOnboardingAgency
          )
        }


        this.handleRemoveFilter = (index, tableName, columnName) => {
          this.onRemoveFilter({
            index,
            tableName,
            columnName
          });
        }


        this.handleFilterClicked = (filter) => {


          const tableName = filter.tableName;


          this.onFilterClicked({
            tableName,
            filter
          })
        }

        this.handleTimePeriodChange = (filter) => {
          if (this.isTimePeriodValid(filter)) {
            filter.value = `${filter.timePeriodValue.interval} ${filter.timePeriodValue.timePeriod}`;
          } else {
            filter.value = "";
          }
        }
        this.isTimePeriodValid = (filter) => {
          return (filter.timePeriodValue.interval !== undefined && filter.timePeriodValue.interval !== null &&
            filter.timePeriodValue.timePeriod !== undefined && filter.timePeriodValue.timePeriod !== "")
        }
        this.handleFilterDescriptionClick = () => {
          // this.onFilterDescriptionClick();
        }
        scope.altInputFormats = ['MM/dd/yyyy'];
        scope.datePickerOptions = {
          locale: {
            format: "MM/DD/YYYY",
          },
          startingDay: "Sunday"
        };
        scope.hideValueOperators = ['IS NULL', 'NOT IS NULL'];

        scope.timePeriodOptions = ["DAY", "WEEK", "MONTH", "YEAR"];
        scope.timePeriodIntervalOptions = ["DAYS", "WEEKS", "MONTHS", "YEARS"];
        scope.timePeriodRelativeIntervalOptions = ["MINUTES", "HOURS", "DAYS"];
        scope.timePeriodOperators = ["LAST X", "THIS X", "NEXT X", "LAST N X", "NEXT N X"];
        scope.timePeriodIntervalOperators = ["LAST N X", "NEXT N X"];
        scope.timePeriodRelativeIntervalOperators = ["LAST REL N X", "NEXT REL N X"];
        scope.nowRelativeTimeComparisonOperator = "NOW REL TIME COMPARE";

        scope.yearsOptionsExtraSettings = {
          displayProp: "name",
          styleActive: true,
          scrollable: true,
          scrollableHeight: '150px',
          selectionLimit: 1,
          smartButtonMaxItems: 1,
          closeOnSelect: true,
          showCheckAll: false,
          showUncheckAll: false
      };

        const generateArrayOfYears = () => {
          var max = new Date().getFullYear();
          var min = 2019;
          var years = []
        
          for (var i = max; i >= min; i--) {
            years.push({
              id: i,
              name: i
            });
          }

          return years;
        }

        scope.yearOptions = generateArrayOfYears();

        var initPageManager = function () {
          $scope.pageManager = itemSearchPageManager.createSearchPageManager("");
          $scope.validatorsDataManager = $scope.pageManager.getValidatorsDataManager();

          $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
          $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
          $scope.serviceCodesDataManager = $scope.pageManager.getServiceCodesDataManager();
          $scope.payCodesDataManager = $scope.pageManager.getPayCodesDataManager();
          $scope.certificationDataManager = $scope.pageManager.getCertificationDataManager();
          $scope.patientStatusesDataManager = $scope.pageManager.getPatientStatusesDataManager();
          $scope.caregiverStatusesDataManager = $scope.pageManager.getCaregiverStatusesDataManager();
          $scope.teamDataManager = $scope.pageManager.getTeamDataManager();
          $scope.coordinatorDataManager = $scope.pageManager.getCoordinatorDataManager({onlyActive: true});
          $scope.pageManager.initFromToDateParams();
          $scope.pageManager.initContractTypesParam();
          $scope.pageManager.initServiceCodesParam();
          $scope.pageManager.initOfficeParam();
          $scope.pageManager.initTeamParam();
          $scope.pageManager.initCoordinatorParam();
          $scope.pageManager.initSearchParam("officeId", "");
          // $scope.pageManager.initSearchParam("contractTypeId", "");
          // initOtherOptionSelections();
          $scope.pageManager.initValidatorsParam();
        };
        initPageManager();

      }
    };
  }
