import { FlexProps, Flex, HeadingProps, Heading } from "@chakra-ui/react";
import React from "react";

const CallTicketRow = (props: FlexProps) => {
  return (
    <Flex
      overflowY={"scroll"}
      borderWidth={1}
      borderRadius={10}
      padding={4}
      direction="column"
      gap={4}
      {...props}
    >
      {props.children}
    </Flex>
  );
};

CallTicketRow.Header = (props: HeadingProps) => {
  return (
    <Heading fontSize={{ "2xl": "lg", base: "sm" }} {...props}>
      {props.children}
    </Heading>
  );
};

CallTicketRow.Content = (props: FlexProps) => {
  return (
    <Flex paddingTop={2} direction={"column"} gap={4} {...props}>
      {props.children}
    </Flex>
  );
};

CallTicketRow.ContentRow = (props: FlexProps) => {
  return (
    <Flex
      direction={"row"}
      gap={{ base: 2, "2xl": 4 }}
      fontSize={"md"}
      alignItems={"center"}
      {...props}
    >
      {props.children}
    </Flex>
  );
};

export default CallTicketRow;
