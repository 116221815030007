import React from "react";
import { Messages } from "../../../../core/api";
import styled from "@emotion/styled";
import { LocalDate } from "@js-joda/core";
import { Button, ButtonGroup, Text } from "@chakra-ui/react";

interface IntakeNextCallDateFilterCardsProps {
  patients: Messages["IntakePatientDashboardDetails"][];
  placeholder: string;
  selected: LocalDate | null;
  startDate: LocalDate | null;
  endDate: LocalDate | null;
  onChange: (value: [LocalDate, LocalDate | null] | null, e: any) => void;
}

interface IntakeNextCallDateFilters {
  [key: string]: [LocalDate, LocalDate | null];
}

// `from` is greater-equal-than (>=) and `to` is lower-than (<).
const farEnoughPastDate = LocalDate.parse("2000-01-01");
const farEnoughFutureDate = LocalDate.parse("2300-01-01");
const dateFilters: IntakeNextCallDateFilters = {
  past: [farEnoughPastDate, LocalDate.now().minusDays(1)],
  yesterday: [LocalDate.now().minusDays(1), LocalDate.now()],
  today: [LocalDate.now(), LocalDate.now().plusDays(1)],
  tomorrow: [LocalDate.now().plusDays(1), LocalDate.now().plusDays(2)],
  future: [LocalDate.now().plusDays(2), farEnoughFutureDate],
};

const getActiveButton = (startDate: LocalDate | null, endDate: LocalDate | null) => {
  for (const key in dateFilters) {
    const [start, end] = dateFilters[key];
    if (startDate && start.isEqual(startDate)) {
      if (endDate === null && end === null) return key;
      if (endDate === null || end === null) continue;
      if (end.isEqual(endDate)) return key;
    }
  }
  return null;
};

const IntakeNextCallDateFilterCards = (props: IntakeNextCallDateFilterCardsProps) => {
  const { callsInThePast, callsForYesterday, callsForToday, callsForTomorrow, callsInTheFuture } =
    (() => {
      let callsInThePast = 0,
        callsForYesterday = 0,
        callsForToday = 0,
        callsForTomorrow = 0,
        callsInTheFuture = 0;

      const now = LocalDate.now();
      for (const patient of props.patients) {
        if (patient.nextCall === null) {
          continue;
        }

        const nextCallDate = patient.nextCall.toLocalDate();
        if (nextCallDate.isBefore(now)) {
          const daysAgo = now.toEpochDay() - nextCallDate.toEpochDay();
          if (daysAgo === 1) {
            callsForYesterday++;
          } else if (daysAgo === 0) {
            callsForToday++;
          } else {
            callsInThePast++;
          }
        } else if (nextCallDate.isAfter(now)) {
          const daysAhead = nextCallDate.toEpochDay() - now.toEpochDay();
          if (daysAhead === 1) {
            callsForTomorrow++;
          } else {
            callsInTheFuture++;
          }
        } else {
          callsForToday++;
        }
      }

      return {
        callsInThePast,
        callsForYesterday,
        callsForToday,
        callsForTomorrow,
        callsInTheFuture,
      };
    })();

  const handleClick = (
    filters: IntakeNextCallDateFilters[string],
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (activeButton === e.currentTarget.name) {
      props.onChange(null, e);
    } else {
      props.onChange(filters, e);
    }
  };

  const activeButton = getActiveButton(props.startDate, props.endDate);
  return (
    <IntakeNextCallDateFilterCards.ButtonGroup variant={"outline"}>
      <IntakeNextCallDateFilterCards.Button
        name={"past"}
        onClick={(e) => handleClick(dateFilters.past, e)}
        isActive={activeButton === "past"}
      >
        <IntakeNextCallDateFilterCards.PrimaryText color={"red.500"}>
          {callsInThePast}
        </IntakeNextCallDateFilterCards.PrimaryText>
        <Text>{"Calls in the past"}</Text>
      </IntakeNextCallDateFilterCards.Button>
      <IntakeNextCallDateFilterCards.Button
        name={"yesterday"}
        isActive={activeButton === "yesterday"}
        onClick={(e) => handleClick(dateFilters.yesterday, e)}
      >
        <IntakeNextCallDateFilterCards.PrimaryText color={"red.500"}>
          {callsForYesterday}
        </IntakeNextCallDateFilterCards.PrimaryText>
        <Text>{"Calls for yesterday"}</Text>
      </IntakeNextCallDateFilterCards.Button>
      <IntakeNextCallDateFilterCards.Button
        name={"today"}
        isActive={activeButton === "today"}
        onClick={(e) => handleClick(dateFilters.today, e)}
      >
        <IntakeNextCallDateFilterCards.PrimaryText color={"#ecc94b"}>
          {callsForToday}
        </IntakeNextCallDateFilterCards.PrimaryText>
        <Text>{"Calls for today"}</Text>
      </IntakeNextCallDateFilterCards.Button>
      <IntakeNextCallDateFilterCards.Button
        name={"tomorrow"}
        isActive={activeButton === "tomorrow"}
        onClick={(e) => handleClick(dateFilters.tomorrow, e)}
      >
        <IntakeNextCallDateFilterCards.PrimaryText color={"green.500"}>
          {callsForTomorrow}
        </IntakeNextCallDateFilterCards.PrimaryText>
        <Text>{"Calls for tomorrow"}</Text>
      </IntakeNextCallDateFilterCards.Button>
      <IntakeNextCallDateFilterCards.Button
        name={"future"}
        isActive={activeButton === "future"}
        onClick={(e) => handleClick(dateFilters.future, e)}
      >
        <IntakeNextCallDateFilterCards.PrimaryText color={"green.500"}>
          {callsInTheFuture}
        </IntakeNextCallDateFilterCards.PrimaryText>
        <Text>{"Calls in the future"}</Text>
      </IntakeNextCallDateFilterCards.Button>
    </IntakeNextCallDateFilterCards.ButtonGroup>
  );
};

IntakeNextCallDateFilterCards.ButtonGroup = styled(ButtonGroup)`
  gap: 5px;
  margin-left: 28px;
`;

IntakeNextCallDateFilterCards.Button = styled(Button)`
  display: flex;
  flex-direction: column;
  min-width: 180px;
  max-width: 180px;
  min-height: 50px;
  max-height: 50px;
`;

IntakeNextCallDateFilterCards.PrimaryText = styled(Text)`
  font-size: 25px;
`;

export default IntakeNextCallDateFilterCards;
