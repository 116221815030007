import React from "react";
import { createIcon } from "@chakra-ui/react";

const TranscriptIcon = createIcon({
  displayName: "TranscriptIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="currentColor">
      <path d="M5 16a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm13-5a1 1 0 1 1 0 2h-8a1 1 0 1 1 0-2h8Zm-2 5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1Zm-9-5a1 1 0 1 1 0 2H6a1 1 0 1 1 0-2h1Z" />
      <path
        fillRule="evenodd"
        d="M4 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H4Zm16 2H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1Z"
        clipRule="evenodd"
      />
    </g>
  ),
});

export default TranscriptIcon;
