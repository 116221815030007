import { useDisclosure, useToast } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { BodyOf, QueryParamsOf } from "../../../../core/api";
import LoadingPage from "../../../../shared/components/LoadingPage";
import useApi from "../../../../shared/hooks/useApi";
import { useQueryParams } from "../../../../shared/hooks/useQueryParams";
import { queryKeys } from "../../../../shared/query-keys";
import { PatientId } from "../../../../shared/schema/schema";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";
import IntakePatientProfileRoute from "../../components/IntakePatientProfileRoute";
import IntakeDashboardPage from "./IntakeDashboardPage";

export interface EligibilityCheckResult {
  isEligible: boolean | null;
  isEligibleOnOtherPayer: boolean;
  eligibilityPayer: string | null;
  createdAt: Instant;
  message: string | null;
}

const IntakeDashboardRoute = () => {
  const { api } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { stateService } = useRouter();
  const createIntakePatientDisclosure = useDisclosure();

  const { mutate: handleCreateNewIntakePatient, reset } = useMutation({
    mutationKey: ["create-new-intake-patient"],
    mutationFn: (body: BodyOf<"post", "./patient_intake">) =>
      api.post("./patient_intake", { body }),
    onSuccess: (intakePatient) => {
      toast({
        title: "Intake Patient Created Successfully",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      createIntakePatientDisclosure.onClose();
      queryClient.invalidateQueries(queryKeys.patientIntake.search(queryParams.params));
      goToPatientIntakeProfile(intakePatient.patient.id);
      reset();
    },
    onError: (error) => {
      toast({
        title: "Could not create new patient",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const queryParams = useQueryParams<QueryParamsOf<"get", "./patient_intake/dashboard">>();

  const medicaidStatuses = useQuery({
    queryKey: queryKeys.patientIntake.medicaidStatus(),
    keepPreviousData: true,
    queryFn: () => api.get("./patient_intake/medicaid_status", {}),
  });

  const intakeStatuses = useQuery({
    queryKey: queryKeys.patientIntake.intakeStatus(),
    keepPreviousData: true,
    queryFn: () => api.get("./patient_intake/intake_status", {}),
  });

  const intakePlans = useQuery({
    queryKey: queryKeys.patientIntake.intakePlan(),
    keepPreviousData: true,
    queryFn: () => api.get("./patient_intake/intake_plan", {}),
  });

  const dashboardData = useQuery({
    queryKey: queryKeys.patientIntake.search(queryParams.params),
    keepPreviousData: true,
    queryFn: () =>
      api.get("./patient_intake/dashboard", {
        query: queryParams.params.toJSON(),
      }),
  });

  const goToPatientIntakeProfile = (patientId: PatientId) => {
    stateService.go("app.patients.intake.profile", {
      patientId,
    });
  };

  if (
    dashboardData.isLoading ||
    medicaidStatuses.isLoading ||
    intakeStatuses.isLoading ||
    intakePlans.isLoading
  ) {
    return <LoadingPage />;
  }

  if (
    dashboardData.isError ||
    medicaidStatuses.isError ||
    intakeStatuses.isError ||
    intakePlans.isError
  ) {
    return <div>Error</div>;
  }

  if (!dashboardData.data || !medicaidStatuses.data || !intakeStatuses.data || !intakePlans.data) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <IntakeDashboardPage
        createIntakePatientDisclosure={createIntakePatientDisclosure}
        intakePatients={dashboardData.data.patients}
        medicaidStatuses={medicaidStatuses.data.medicaidStatuses}
        intakeStatuses={intakeStatuses.data.intakeStatuses}
        intakePlans={intakePlans.data.intakePlans}
        filters={queryParams}
        onChangeFilter={queryParams.setValue}
        onChangeFilters={queryParams.setValues}
        onCreateNewIntakePatient={handleCreateNewIntakePatient}
        onClickDashboardRow={goToPatientIntakeProfile}
      />
      <IntakePatientProfileRoute />
    </>
  );
};

export default IntakeDashboardRoute;
