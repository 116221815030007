import PhoneNumber from "@../../awesome-phonenumber";

function createFormatter() {
  function isValidNumber(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.isValid();
  }
  function formatE164(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.getNumber("e164");
  }
  function formatNational(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.getNumber("national");
  }

  function formatNationalIfValid(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.isValid() ? phoneNumber.getNumber("national") : number;
  }

  function formatNumberWithoutCountryPrefix(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.getNumber("significant");
  }

  return {
    isValidNumber,
    formatE164,
    formatNational,
    formatNationalIfValid,
    formatNumberWithoutCountryPrefix,
  };
}

export const phoneFormatter = createFormatter();
