import { IconButton } from "@chakra-ui/react";
import React from "react";
import BaselineMinusIcon from "../../../shared/icons/BaselineMinusIcon";

export const MinusButton = (props: Omit<React.ComponentProps<typeof IconButton>, "aria-label">) => (
  <IconButton
    aria-label="Minus"
    variant="outline"
    borderStyle="dashed"
    borderColor="gray.300"
    icon={<BaselineMinusIcon fontSize="sm" />}
    {...props}
  />
);
